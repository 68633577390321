import '../styles/contactUs-button.css';
import closeCross from '../../images/close-regularBlue.png';
import clipImage from '../../images/clip-light.png';
import deleteFileIcon from '../../images/PeoplExCancel.svg';
import DOMpurify from 'dompurify';
import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { createJiraTicket } from '../../services/apiJira';
import { readFileAsBase64, ALLOWED_TYPE_FILES } from '../../utils/utils';
const secretKey = 'your-secret-key';
const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

const ContactUsButton = ({ userInfoSSFF, categoriesData }) => {
  const [feedbackText, setFeedbackText] = useState('');

  const [files, setFiles] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);

  //pop up de gracias por tu consulta
  const [questionSentPopup, setQuestionSentPopup] = useState(false);
  const [showTeamTextarea, setShowTeamTextarea] = useState(false);
  const [showPersonalTextarea, setShowPersonalTextarea] = useState(false);

  //Mide si el pop-up empleados está abierto o no
  const [employeeAssistancePopupOpen, setEmployeeAssistancePopupOpen] = useState(false);

  //Mide si el pop-up managers está abierto o no
  const [managerAssistancePopupOpen, setManagerAssistancePopupOpen] = useState(false);

  //Estas dos variables estados miden si el input está seleccionado o no. La primera el de necesidad personal y la segunda la de necesidad de equipo
  const [personalAssistanceSelected, setPersonalAssistanceSelected] = useState(false);
  const [teamAssistanceSelected, setTeamAssistanceSelected] = useState(false);
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);

  const [employeeCategories, setEmployeeCategories] = useState([]);
  const [managerCategories, setManagerCategories] = useState([]);
  // Estado para manejar la opción seleccionada
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleChange = (e) => {
    setSelectedCategory(e.target.value); // Actualiza la opción seleccionada
  };

  useEffect(() => {
    handleCategories();
  }, [categoriesData]);

  const handleCategories = () => {
    // Filtrar y asignar las categorías según su audience
    const businessCategories = categoriesData.filter((category) => category.audience === 'BusinessEX');

    const employeeCategories = categoriesData.filter((category) => category.audience === 'PeoplEX');

    // Guardar en los estados correspondientes
    setManagerCategories(businessCategories);
    setEmployeeCategories(employeeCategories);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (ALLOWED_TYPE_FILES.includes(selectedFiles[0].name.split('.').pop())) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setShowFormatNotSupported(false);
    } else {
      setShowFormatNotSupported(true); // Mostrar el mensaje de formato no soportado
    }
  };

  const openPopup = () => {
    if ((userInfoSSFF.custom15 === 'Y' && userInfoSSFF.custom03 === '1893') || userInfoSSFF.custom03 === '1702' || userInfoSSFF.custom03 === '1017') {
      setEmployeeAssistancePopupOpen(true);
    } else if (userInfoSSFF.custom15 === 'Y') {
      setManagerAssistancePopupOpen(true);
    } else {
      setEmployeeAssistancePopupOpen(true);
    }
  };

  const closePopup = () => {
    setManagerAssistancePopupOpen(false);
    setEmployeeAssistancePopupOpen(false);
    // Restablecer los estados de los radio buttons cuando se cierra el popup
    setPersonalAssistanceSelected(false);
    setTeamAssistanceSelected(false);
    setShowTeamTextarea(false);
    setShowPersonalTextarea(false);
    setQuestionSentPopup(false);
    setFeedbackText('');
    setFiles([]);
    setShowFormatNotSupported(false);
  };

  const questionSent = async () => {
    let titleTicket = 'SOPORTE EMPLEADO';
    let tag = 'Empleado';

    try {
      setIsSubmitting(true);

      const sanitize = (value) => DOMpurify.sanitize(value);

      if (managerAssistancePopupOpen && personalAssistanceSelected) {
        titleTicket = 'SOPORTE EMPLEADO';
      } else if (managerAssistancePopupOpen && teamAssistanceSelected) {
        titleTicket = 'SOPORTE MANAGER';
        tag = 'Manager';
      }

      // Lee los archivos en base64
      const filesAttached =
        files.length > 0
          ? await Promise.all(
              files.map(async (file) => {
                const base64 = await readFileAsBase64(file);
                return {
                  name: file.name,
                  content: encryptValue(base64),
                };
              })
            )
          : [];

      const fields = {
        employeeName: encryptValue(sanitize(userInfoSSFF.displayName)),
        employeeEmail: encryptValue(sanitize(userInfoSSFF.username)),
        legalEntity: encryptValue(sanitize(userInfoSSFF.custom03)),
        division: encryptValue(sanitize(userInfoSSFF.division)),
        area: encryptValue(sanitize(userInfoSSFF.custom02)),
        department: encryptValue(sanitize(userInfoSSFF.department)),
        consult: encryptValue(sanitize(feedbackText)),
        tag: encryptValue(sanitize(tag)),
        category: encryptValue(sanitize(selectedCategory)),
        filesAttached: filesAttached,
      };

      let ticketData = {
        summary: titleTicket,
        issueType: tag === 'Manager' ? 'Soporte Manager' : 'Soporte Empleado',
        priority: 'Medium',
      };

      const customFields = {
        customfield_10318: fields.employeeName,
        customfield_10319: fields.employeeEmail,
        customfield_10322: fields.legalEntity,
        customfield_10347: fields.division,
        customfield_10348: fields.area,
        customfield_10349: fields.department,
        customfield_10350: fields.consult,
        customfield_10351: fields.tag,
        customfield_10373: fields.category,
      };

      Object.keys(ticketData).forEach((key) => {
        if (ticketData[key] === '' || ticketData[key] === 0) {
          delete ticketData[key];
        }
      });

      ticketData = { ...ticketData, ...customFields };
      await createJiraTicket(ticketData, fields.filesAttached);
      setFiles([]);
      setSelectedCategory('');
      setManagerAssistancePopupOpen(false);
      setPersonalAssistanceSelected(false);
      setQuestionSentPopup(true);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setMessageSendingFailed(true);
    } finally {
      setIsSubmitting(false);
      setSelectedCategory('');
    }
  };

  const closeQuestionSentPopup = () => {
    setQuestionSentPopup(false);
    closePopup();
    setMessageSendingFailed(false);
  };
  const handlePersonalAssistanceChange = () => {
    setPersonalAssistanceSelected(!personalAssistanceSelected);
    // Asegúrate de que el otro radio esté deseleccionado al cambiar
    setTeamAssistanceSelected(false);
    setShowTeamTextarea(false);
    setShowPersonalTextarea(true);
    setFeedbackText('');
    setFiles([]);
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    document.getElementById('files').value = null;
  };

  const handleTeamAssistanceChange = () => {
    setTeamAssistanceSelected(!teamAssistanceSelected);
    // Asegúrate de que el otro radio esté deseleccionado al cambiar
    setPersonalAssistanceSelected(false);
    setShowPersonalTextarea(false);
    setShowTeamTextarea(true);
    setFeedbackText('');
    setFiles([]);
  };
  const handleFeedbackTextChange = (event) => {
    const text = event.target.value;
    setFeedbackText(text);
  };
  const isSubmitEnabled = feedbackText || teamAssistanceSelected;
  return (
    <>
      <section className='contactUsButtonSection'>
        <button className='contactUsButton' onClick={openPopup}>
          Contacta con nosotros
        </button>
      </section>
      {/* Popup */}
      {employeeAssistancePopupOpen && (
        <div className='assistancePopup'>
          <div className='assistancePopup__content employeeContent'>
            <div className='assistancePopup__titleSection'>
              <h3 className='assistancePopup__title'>¿En qué podemos ayudarte?</h3>
              <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={closePopup} />
            </div>
            <div className='assistancePopup__textSection'>
              <p className='assistancePopup__message employeeMessage'>Contactaremos contigo a la mayor brevedad posible.</p>
            </div>
            <p>{feedbackText.length}/255 caracteres permitidos</p>
            <textarea
              className='assistancePopup__feedbackText'
              placeholder='Cuéntanos qué necesitas.'
              value={feedbackText}
              maxLength={255} // Limita los caracteres a 255
              onChange={(e) => {
                if (e.target.value.length <= 255) {
                  handleFeedbackTextChange(e); // Actualiza el estado del texto
                }
              }}
            ></textarea>
            {showFormatNotSupported && (
              <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
            )}
            {files.length !== 0 && (
              <div className='assistancePopup__fileSection'>
                {files.map((file, index) => (
                  <>
                    <div className={'assistancePopup__fileSection__fileSelected'}>
                      <p className={'assistancePopup__fileSection--fileName'}>{`${file.name}`}</p>
                      <img
                        className='assistancePopup__fileSection--deleteFileIcon'
                        src={deleteFileIcon}
                        alt='deleteFile'
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
            <div className='assistancePopup__buttonSection'>
              <button className='assistancePopup__uploadFiles'>
                <input onChange={handleFileChange} type='file' className='assistancePopup__uploadFiles--input' id='files' />
                <label htmlFor='files'>
                  <img src={clipImage} className='assistancePopup__uploadFiles--clip' alt='Adjuntar archivos' />
                </label>
              </button>
              <button
                className={`assistancePopup__closeButton ${isSubmitEnabled ? 'enabled' : ''}`}
                onClick={questionSent}
                disabled={!isSubmitEnabled}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
      {managerAssistancePopupOpen && (
        <div className='assistancePopup'>
          <div className='assistancePopup__content'>
            <div className='assistancePopup__titleSection'>
              <h3 className='assistancePopup__title'>¿En qué podemos ayudarte?</h3>
              <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={closePopup} />
            </div>
            <div className='assistancePopup__textSection'>
              <p className='assistancePopup__message'>Contactaremos contigo a la mayor brevedad posible.</p>
              <div className='assistancePopup__textOptions'>
                <form action='#' method='post' target='_blank'>
                  <div className='assistancePopup__inputsContainer'>
                    <div className='assistancePopup__option'>
                      <input
                        type='radio'
                        id='personal'
                        name='assistance'
                        checked={personalAssistanceSelected}
                        onChange={handlePersonalAssistanceChange}
                      />
                      <label htmlFor='personal' className='assistancePopup__textOptions--label'>
                        Tengo una necesidad personal
                        <p className='assistancePopup__textOptions--desc'>Por ejemplo: Mi nómina y beneficios, mis vacaciones y permisos...</p>
                      </label>
                    </div>
                    <div className='assistancePopup__option'>
                      <input type='radio' id='team' name='assistance' checked={teamAssistanceSelected} onChange={handleTeamAssistanceChange} />
                      <label htmlFor='team' className='assistancePopup__textOptions--label'>
                        Tengo una necesidad sobre mi equipo
                        <p className='assistancePopup__textOptions--desc'>
                          Por ejemplo: Atracción y retención de talento para mi equipo, formación y desarrollo para mi equipo...
                        </p>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              {showPersonalTextarea === true ? (
                <>
                  <select value={selectedCategory} onChange={handleChange}>
                    <option value='' hidden>
                      Categoría
                    </option>
                    {employeeCategories.map((option) => (
                      <option key={option.category_id} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                    <option value='otros'>Otros...</option>
                  </select>
                  <div>
                    {/* Contador de caracteres */}
                    <p>{feedbackText.length}/255 caracteres permitidos</p>
                    <textarea
                      className='assistancePopup__feedbackText'
                      placeholder='Cuéntanos qué necesitas.'
                      value={feedbackText}
                      maxLength={255} // Limita los caracteres a 255
                      onChange={(e) => {
                        if (e.target.value.length <= 255) {
                          handleFeedbackTextChange(e); // Actualiza el estado del texto
                        }
                      }}
                    ></textarea>
                  </div>
                </>
              ) : null}
              {showTeamTextarea === true ? (
                <>
                  <select value={selectedCategory} onChange={handleChange}>
                    <option value='' hidden>
                      Categoría
                    </option>
                    {managerCategories.map((option) => (
                      <option key={option.category_id} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                    <option value='otros'>Otros...</option>
                  </select>
                  <div>
                    {/* Contador de caracteres */}
                    <p>{feedbackText.length}/255 caracteres permitidos</p>
                    <textarea
                      className='assistancePopup__feedbackText'
                      placeholder='(Opcional) Cuéntanos qué necesitas.'
                      value={feedbackText}
                      maxLength={255} // Limita los caracteres a 255
                      onChange={(e) => {
                        if (e.target.value.length <= 255) {
                          handleFeedbackTextChange(e); // Actualiza el estado del texto
                        }
                      }}
                    ></textarea>
                  </div>
                </>
              ) : null}
            </div>
            {files.length !== 0 && (
              <div className='assistancePopup__fileSection'>
                {files.map((file, index) => (
                  <>
                    <div className={'assistancePopup__fileSection__fileSelected'}>
                      <p className={'assistancePopup__fileSection--fileName'}>{`${file.name}`}</p>
                      <img
                        className='assistancePopup__fileSection--deleteFileIcon'
                        src={deleteFileIcon}
                        alt='deleteFile'
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
            {showFormatNotSupported && (
              <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
            )}
            <div className='assistancePopup__buttonSection'>
              {isSubmitting && (
                // SPINNER LOADING
                <div className='loader'></div>
              )}
              <button className='assistancePopup__uploadFiles'>
                <input onChange={handleFileChange} type='file' className='assistancePopup__uploadFiles--input' id='files' multiple />
                <label htmlFor='files'>
                  <img src={clipImage} className='assistancePopup__uploadFiles--clip' alt='Adjuntar archivos' />
                </label>
              </button>
              <button
                className={`assistancePopup__closeButton ${isSubmitEnabled ? 'enabled' : ''}`}
                onClick={questionSent}
                disabled={!isSubmitEnabled}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
      {questionSentPopup === true ? (
        <div className='assistancePopup'>
          <div className='assistancePopup__content'>
            <div className='assistancePopup__titleSection'>
              <h3 className='assistancePopup__title'>Hemos recibido tu consulta</h3>
            </div>
            <div className='assistancePopup__textSection'>
              <p className='assistancePopup__message'>Contactaremos contigo a la mayor brevedad posible.</p>
            </div>
            <div className='assistancePopup__buttonSection'>
              <button className='assistancePopup__closeButton enabled' onClick={closeQuestionSentPopup}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {messageSendingFailed && (
        <div className='dislikePopup-overlay'>
          <div className='dislikePopup-content failurePopup'>
            <div className='dislikePopup__sectionText'>
              <p className='timeOutPopup__sectionText--text'>
                No se ha podido enviar su mensaje. <br /> Por favor, vuelve a probar transcurrido unos minutos. Si el error persiste, contacta con
                nosotros a través de{' '}
              </p>
              <a href='mailto:preguntanos@personas.telefonica.com' target='_blank' rel='noreferrer'>
                preguntanos@personas.telefonica.com
              </a>
            </div>
            <div className='contactMeSectionButton'>
              <button className='contactMePopup--button failureButton' onClick={closeQuestionSentPopup}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsButton;
