import DOMpurify from 'dompurify';
import CryptoJS from 'crypto-js';
import { updateJiraTicket } from '../../../../../../../services/apiJira';
import { validateFields } from './validateFields';

const secretKey = 'your-secret-key';

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

const handleSubmit = async (
  e,
  formData,
  isButtonEnabled,
  setIsSubmitting,
  handleClearForm,
  updatePopupState,
  setPopupState,
  // setMessageSendingFailed,
  filesAttached,
  ticketId
) => {
  e.preventDefault();

  if (isButtonEnabled) {
    try {
      setIsSubmitting(true);

      const sanitize = (value) => DOMpurify.sanitize(value || '');
      const encrypt = (value) => encryptValue(value);

      // Reemplazar valores vacíos por 'default' o '0'
      const validatedLegalEntity = formData.legalEntity || 'default';
      const validatedHours = formData.hours || 'default'; //Funciona si selecciona un valor, sino envia: customfield_10431: { value: '[object Object]' },
      const validatedJcLevel = formData.jcLevel || 'default';
      const validatedOrganizationalChangeStatus = formData.organizationalChangeStatus || 'default';
      const validatedSalaryChangeStatus = formData.salaryChangeStatus || 'default';
      const validatedLocationChangeStatus = formData.locationChangeStatus || 'default';
      const validatedContractChangeStatus = formData.contractChangeStatus || 'default';

      const customFields = {
        // Cambios Organizativos
        customfield_10383: encrypt(sanitize(formData.organizationalChange)),
        customfield_10384: encrypt(sanitize(formData.newDepartments)),
        customfield_10385: encrypt(sanitize(formData.newManager)),
        customfield_10386: encrypt(sanitize(formData.newAsigneeManager)),
        customfield_10387: encrypt(sanitize(formData.roleChange)),
        customfield_10391: encrypt(sanitize(formData.roleChangeDetails)),
        customfield_10389: encrypt(sanitize(formData.newDuties)),
        customfield_10390: encrypt(sanitize(formData.newDutiesDetails)),
        customfield_10392: encrypt(sanitize(formData.categoryChange)),
        customfield_10393: encrypt(sanitize(formData.categoryChangeDetails)),
        customfield_10394: encrypt(sanitize(formData.hrbp)),
        customfield_10395: encrypt(sanitize(formData.businessUnit)),
        customfield_10396: encrypt(sanitize(formData.division)),
        customfield_10349: encrypt(sanitize(formData.department)),
        customfield_10397: encrypt(sanitize(formData.costCenter)),
        customfield_10398: encrypt(sanitize(formData.objectivesResult)),
        customfield_10399: encrypt(sanitize(formData.newJobClasification)),
        customfield_10400: encrypt(sanitize(formData.jcTitle)),
        customfield_10401: encrypt(sanitize(validatedJcLevel)),
        customfield_10402: encrypt(sanitize(formData.jcPayGrade)),
        customfield_10403: encrypt(sanitize(formData.jcTelefonicaGrade)),
        customfield_10405: encrypt(sanitize(formData.jcTelefonicaLevel)),
        customfield_10406: encrypt(sanitize(validatedLegalEntity)),
        customfield_10407: encrypt(sanitize(formData.jobFunction)),
        customfield_10408: encrypt(sanitize(formData.jcCreationDate)),
        customfield_10409: encrypt(sanitize(formData.jcCreated)),
        customfield_10410: encrypt(sanitize(formData.jobClassification)),
        customfield_10411: encrypt(sanitize(formData.globalRole)),
        customfield_10412: encrypt(sanitize(formData.category)),
        customfield_10415: encrypt(sanitize(formData.benchmarkCode)),
        customfield_10416: encrypt(sanitize(formData.newJobPosition)),
        customfield_10420: encrypt(sanitize(formData.newPositionCreated)),
        customfield_10421: encrypt(sanitize(formData.employeeAssignedToNewPosition)),
        customfield_10422: encrypt(sanitize(formData.positionCode)),
        customfield_10431: encrypt(sanitize(validatedHours)),
        customfield_10462: encrypt(sanitize(formData.positionChangeRequired)),
        customfield_10463: encrypt(sanitize(formData.positionChangeComments)),
        // Cambios Salariales
        customfield_10423: encrypt(sanitize(formData.salaryChange)),
        customfield_10424: encrypt(sanitize(formData.currency)),
        customfield_10425: encrypt(sanitize(formData.bonusOrIncentives)),
        customfield_10426: encrypt(sanitize(formData.incentivesAmount)),
        customfield_10427: encrypt(sanitize(formData.salaryReason)),
        // Cambios de Localización
        customfield_10428: encrypt(sanitize(formData.city)),
        customfield_10343: encrypt(sanitize(formData.country)),
        customfield_10344: encrypt(sanitize(formData.building)),
        customfield_10429: encrypt(sanitize(formData.floor)),
        // Cambios de Contrato
        customfield_10430: encrypt(sanitize(formData.contractChange)),
        customfield_10431: encrypt(sanitize(formData.hours)),
        // Otros
        customfield_10432: encrypt(sanitize(validatedOrganizationalChangeStatus)),
        customfield_10433: encrypt(sanitize(validatedSalaryChangeStatus)),
        customfield_10434: encrypt(sanitize(validatedLocationChangeStatus)),
        customfield_10435: encrypt(sanitize(validatedContractChangeStatus)),
        customfield_10440: encrypt(sanitize(formData.changeRequestCompleted)),
      };

      let ticketData = {
        summary: `Cambio`,
        issueType: 'Cambio',
        priority: 'Medium',
        ...customFields,
      };

      if (ticketId) {
        ticketData.ticketId = ticketId;
      }

      if (Array.isArray(filesAttached)) {
        ticketData.filesAttached = filesAttached;
      } else {
        ticketData.filesAttached = [];
      }

      // Eliminar campos encriptados que tengan valor `undefined` para evitar errores en la API
      Object.keys(customFields).forEach((key) => {
        if (customFields[key] === '' || customFields[key] === 0) {
          delete customFields[key];
        }
      });

      ticketData = { ...ticketData, ticketId, filesAttached };

      const response = await updateJiraTicket(ticketData, ticketId, filesAttached);

      // Verifica si el estado es 204 para mostrar el popup
      if (response.status === 204) {
        const newPopupState = { isVisible: true, message: 'El ticket ha sido actualizado correctamente.' };
        setPopupState(newPopupState); // Actualiza el estado original
      } else {
        console.error('Error: Código de estado no esperado:', response.status);
        const errorPopupState = { isVisible: true, message: 'No se ha podido actualizar su ticket, compruebe sus datos.' };
        setPopupState(errorPopupState); // Muestra el popup con el mensaje de error
      }
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      alert('Error al enviar el mensaje');
      // setMessageSendingFailed(true);
    } finally {
      // setIsSubmitting(false);
    }
  }
};

export default handleSubmit;
