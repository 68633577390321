import React, { useEffect, useState } from 'react';
import { validateFields } from '../validateFields';
import elipse242 from '../../../../../../../../images/ellipse242.png';

export const LocationChangePeople = ({ formData, handleInputChange, errors = {}, setErrors }) => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    // Validar campos al iniciar y al cambiar
    const fields = {
      city: formData.city,
      country: formData.country,
      building: formData.building,
      floor: formData.floor,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [formData, setErrors]);

  const getInputClass = (fieldValue, fieldName) => {
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios de localización</div>

      <div className='formJiraChangeSection_both'>
        {/* Campo: Ciudad */}
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.city && '*'} Ciudad:
          <input
            type='text'
            className={getInputClass(formData.city, 'city')}
            value={formData.city || ''}
            onChange={(e) => handleInputChange('city', e.target.value)}
            placeholder='Ciudad...'
          />
        </label>

        {/* Campo: País */}
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.country && '*'} País:
          <input
            type='text'
            className={getInputClass(formData.country, 'country')}
            value={formData.country || ''}
            onChange={(e) => handleInputChange('country', e.target.value)}
            placeholder='País...'
          />
        </label>
      </div>

      <div className='formJiraChangeSection_both'>
        {/* Campo: Edificio */}
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.building && '*'} Edificio:
          <input
            type='text'
            className={getInputClass(formData.building, 'building')}
            value={formData.building || ''}
            onChange={(e) => handleInputChange('building', e.target.value)}
            placeholder='Edificio...'
          />
        </label>

        {/* Campo: Planta */}
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.floor && '*'} Planta:
          <input
            type='text'
            className={getInputClass(formData.floor, 'floor')}
            value={formData.floor || ''}
            onChange={(e) => handleInputChange('floor', e.target.value)}
            placeholder='Planta...'
          />
        </label>
      </div>
    </div>
  );
};
