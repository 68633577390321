import { useState } from 'react';
import addIcon from '../../images/adminPanel/add.png';
import editIcon from '../../images/adminPanel/edit.png';
import verIcon from '../../images/adminPanel/ver.png';
import '../styles/categoryList.css';
import '../styles/spinnerLoading.css';

const CategoryList = ({ items, isLoading, reloadData }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

  const handleViewClick = (item) => {
    setSelectedCategory(item);
    setIsEditMode(false);
    setIsPopupVisible(true);
  };
  const handleAddClick = () => {
    setIsAddPopupVisible(true);
    setFormData({
      name: '',
      audience: 'Managers',
      category_img: null,
      is_active: false,
    });
  };

  const handleEditClick = (item) => {
    setSelectedCategory(item);
    setFormData({
      name: item.name,
      audience: item.audience,
      category_img: item.category_img || null,
      is_active: item.is_active,
    });
    setIsEditMode(true);
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setIsAddPopupVisible(false);
    setSelectedCategory(null);
    setIsEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData({ ...formData, category_img: reader.result.split(',')[1] });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch('/updateCategories', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...selectedCategory, ...formData }),
      });
      if (response.ok) {
        setIsPopupVisible(false);
        reloadData();
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      alert('Error al actualizar la categoría.');
    }
  };

  const handleAddCategory = async () => {
    try {
      const response = await fetch('/addCategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setIsAddPopupVisible(false);
        reloadData();
      } else {
        alert('Error al añadir la categoría.');
      }
    } catch (error) {
      console.error('Error al añadir la categoría:', error);
      alert('Error al añadir la categoría.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ display: 'flex', padding: '2em 0 0 2em', height: '80px', gap: '1em', alignItems: 'center', alignContent: 'left', width: '100%' }}>
        <img
          src={addIcon}
          alt='Añadir'
          className='itemList__icon'
          style={{
            height: '18px',
            width: '18px',
          }}
          onClick={handleAddClick}
        />
      </div>
      {isLoading ? (
        <div className='spinner-box'>
          <div className='pulse-container'>
            <div className='pulse-bubble pulse-bubble-1'></div>
            <div className='pulse-bubble pulse-bubble-2'></div>
            <div className='pulse-bubble pulse-bubble-3'></div>
          </div>
        </div>
      ) : items.length > 0 ? (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '5%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Id
              </th>
              <th scope='col' style={{ width: '60%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Nombre Categoría
              </th>
              <th scope='col' style={{ textAlign: 'start', fontWeight: 'bold' }}>
                Audiencia
              </th>
              <th scope='col' style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Estado
              </th>
              <th scope='col'></th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {items
              .sort((a, b) => a.category_id - b.category_id)
              .map((item, index) => (
                <tr key={index}>
                  <td style={{ width: '50', textAlign: 'start', paddingLeft: '2em' }}>{item.category_id}</td>
                  <td style={{ width: '60%', textAlign: 'start', paddingLeft: '2em' }}>{item.name}</td>
                  <td style={{ textAlign: 'start' }}>{item.audience}</td>
                  <td style={{ textAlign: 'center' }}>
                    <button
                      style={{
                        fontSize: '11px',
                        padding: '3px 5px',
                        borderRadius: '5px',
                        border: item.is_active ? '1px solid #06f' : '1px solid grey',
                        backgroundColor: item.is_active ? '#06f' : 'white',
                        color: item.is_active ? 'white' : 'black',
                        width: '90%',
                        cursor: 'auto',
                      }}
                    >
                      {item.is_active ? 'Activo' : 'Desactivado'}
                    </button>
                  </td>
                  <td>
                    <img src={verIcon} alt='Ver' className='itemList__icon' onClick={() => handleViewClick(item)} style={{ cursor: 'pointer' }} />
                  </td>
                  <td>
                    <img src={editIcon} alt='Editar' className='itemList__icon' onClick={() => handleEditClick(item)} style={{ cursor: 'pointer' }} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '60%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Nombre Categoría
              </th>
              <th scope='col' style={{ textAlign: 'start', fontWeight: 'bold' }}>
                Scope
              </th>
              <th scope='col' style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Estado
              </th>
              <th scope='col'></th>
              <th scope='col'></th>
            </tr>
          </thead>
        </table>
      )}
      {/* Popup */}
      {isPopupVisible && selectedCategory && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button
                  onClick={handleClosePopup}
                  style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em' }}
                >
                  &times;
                </button>
              </div>
              <div className='catPopup__infoContainer'>
                {isEditMode ? (
                  <form>
                    <div className='mb-3'>
                      <div className='mb-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <label htmlFor='categoryImage' className='form-label'>
                          <strong>Imagen de la categoría:</strong>
                        </label>
                        {formData.category_img && (
                          <img
                            src={`data:image/jpeg;base64,${formData.category_img}`}
                            alt='Imagen actual'
                            className='img-thumbnail'
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                          />
                        )}
                        <br />
                        <small>Tamaño requerido: 693x390</small>
                      </div>
                      <input
                        type='file'
                        className='form-control'
                        id='categoryImage'
                        name='category_img'
                        onChange={handleFileChange}
                        accept='image/*'
                      />
                    </div>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      <div>
                        <label htmlFor='categoryName' className='form-label'>
                          <strong>Nombre de la categoría:</strong>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='categoryName'
                          name='name'
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='categoryAudience' className='form-label'>
                          <strong>Audiencia:</strong>
                        </label>
                        <select className='form-select' id='categoryAudience' name='audience' value={formData.audience} onChange={handleInputChange}>
                          <option value='BusinessEX'>Managers</option>
                          <option value='People'>People</option>
                          <option value='PeoplEX'>All Employees</option>
                        </select>
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='categoryStatus' className='form-label'>
                          <strong>Estado:</strong>
                        </label>
                        <select className='form-select' id='categoryStatus' name='is_active' value={formData.is_active} onChange={handleInputChange}>
                          <option value='true'>Activo</option>
                          <option value='false'>Desactivado</option>
                        </select>
                      </div>
                    </div>

                    <button type='button' className='btn btn-primary' onClick={handleSaveChanges}>
                      Guardar cambios
                    </button>
                  </form>
                ) : (
                  <>
                    {selectedCategory.category_img && (
                      <div style={{ width: '150px', maxHeight: '150px', height: 'fit-content' }}>
                        <img
                          src={`data:image/jpeg;base64,${selectedCategory.category_img}`}
                          alt={selectedCategory.name}
                          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                        />
                      </div>
                    )}
                    <p>
                      <strong>Nombre de la categoría: </strong>
                      {selectedCategory.name}
                    </p>
                    <p>
                      <strong>Audiencia:</strong> {selectedCategory.audience}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Popup para añadir */}
      {isAddPopupVisible && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button onClick={handleClosePopup} style={{ float: 'right', border: 'none', background: 'none', fontSize: '1.5em' }}>
                  &times;
                </button>
                <h5>Añadir Nueva Categoría</h5>
              </div>
              <form>
                <div className='mb-3'>
                  <label htmlFor='categoryImage' className='form-label'>
                    <strong>Imagen de la categoría:</strong> <br />
                    <small>Tamaño requerido: 693x390</small>
                  </label>
                  <input type='file' className='form-control' id='categoryImage' name='category_img' onChange={handleFileChange} accept='image/*' />
                </div>
                <div className='mb-3'>
                  <label htmlFor='categoryName' className='form-label'>
                    <strong>Nombre de la categoría:</strong>
                  </label>
                  {console.log('formData', formData)}
                  <input type='text' className='form-control' id='categoryName' name='name' value={formData.name} onChange={handleInputChange} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '1em' }} className='mb-3'>
                  <div className='mb-3' style={{ width: '50%' }}>
                    <label htmlFor='categoryAudience' className='form-label'>
                      <strong>Audiencia:</strong>
                    </label>
                    <select className='form-select' id='categoryAudience' name='audience' value={formData.audience} onChange={handleInputChange}>
                      <option value='BusinessEX'>Managers</option>
                      <option value='People'>People</option>
                      <option value='PeoplEX'>All Employees</option>
                    </select>
                  </div>
                  <div className='mb-3' style={{ width: '50%' }}>
                    <label htmlFor='categoryStatus' className='form-label'>
                      <strong>Estado:</strong>
                    </label>
                    <select className='form-select' id='categoryStatus' name='is_active' value={formData.is_active} onChange={handleInputChange}>
                      <option value='true'>Activo</option>
                      <option value='false'>Desactivado</option>
                    </select>
                  </div>
                </div>
                <button type='button' className='btn btn-primary' onClick={handleAddCategory}>
                  Añadir Categoría
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
