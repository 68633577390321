import { useState, useEffect } from 'react';
import editIcon from '../../images/adminPanel/edit.png';
import filterIcon from '../../images/adminPanel/filter.png';
import verIcon from '../../images/adminPanel/ver.png';
import '../styles/articleList.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Función para normalizar strings (eliminar acentos y convertir a minúsculas)
const normalizeString = (str) => {
  return str
    .toString()
    .normalize('NFD') // Descompone caracteres acentuados
    .replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
    .toLowerCase(); // Convierte a minúsculas
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // Estilos de texto
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }], // Encabezados
    [{ list: 'ordered' }, { list: 'bullet' }], // Listas
    [{ script: 'sub' }, { script: 'super' }], // Sub y superíndice
    [{ indent: '-1' }, { indent: '+1' }], // Sangría
    [{ direction: 'rtl' }], // Dirección del texto
    [{ size: ['small', false, 'large', 'huge'] }], // Tamaño de fuente
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // Colores de texto y fondo
    [{ font: [] }],
    [{ align: [] }],
    ['link', 'image', 'video'], // Adjuntar links, imágenes y videos
    ['clean'], // Eliminar estilos
  ],
};
const ArticleList = ({ items, isLoading, reloadData, userInfoSSFF, currentDate }) => {
  let filterOptions = [];
  const [filterKey, setFilterKey] = useState(filterOptions[0]?.value || '');
  const [filterValue, setFilterValue] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedLegalEntity, setSelectedLegalEntity] = useState('default');
  const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);
  const [isRelatedContentPopupVisible, setIsRelatedContentPopupVisible] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [legalEntities, setLegalEntities] = useState([]);

  useEffect(() => {
    // Recuperar las categorías desde localStorage
    const storedCategories = localStorage.getItem('subcategories');
    if (storedCategories) {
      setSubcategories(JSON.parse(storedCategories));
    }
  }, []);

  useEffect(() => {
    if (filterOptions.length > 0 && !filterOptions.some((opt) => opt.value === filterKey)) {
      setFilterKey(filterOptions[0].value);
    }
  }, [filterOptions]);

  // Opciones del filtro
  filterOptions = [
    { label: 'Título', value: 'article_title' },
    { label: 'Subcategoría', value: 'subcategory_name' },
  ];

  // Filtrar los elementos según el valor introducido por el usuario
  const filteredItems = items.filter((item) => {
    if (!filterKey || !filterValue) return true; // Mostrar todos los elementos si no hay filtro
    const field = item[filterKey]?.toString();
    if (filterKey === 'is_active') {
      // Para el estatus, filtra si coincide con "activo" o "desactivado"
      const Estado = item.is_active ? 'activo' : 'desactivado';
      return normalizeString(Estado).includes(normalizeString(filterValue));
    }
    if (filterKey === 'is_highlighted') {
      // Para destacado, filtra si coincide con "destacado" o "no destacado"
      const Destacado = item.is_highlighted ? 'destacado' : 'no destacado';
      return normalizeString(Destacado).includes(normalizeString(filterValue));
    }
    return normalizeString(field).includes(normalizeString(filterValue));
  });

  const filterToggle = () => {
    setFilterVisible(!filterVisible);
  };

  const handleViewClick = async (item) => {
    setIsEditMode(false);
    setIsPopupVisible(true);
    try {
      const response = await fetch(`/articleDetails/${item.article_id}`);
      const data = await response.json();
      setSelectedArticle(data.articleDetails[0]);
    } catch (error) {
      console.error('Error fetching article details:', error);
    }
  };

  const handleContentChange = (value) => {
    // Si el contenido por defecto está seleccionado
    if (selectedLegalEntity === 'default') {
      setFormData({
        ...formData,
        article_content: value, // Actualizamos el contenido por defecto
      });
    } else {
      // Si el contenido alternativo está seleccionado
      const updatedAlternatives = formData.alternative_articles.map((alt) => {
        if (alt.legal_entity_id === selectedLegalEntity) {
          return {
            ...alt,
            alternative_content: value, // Actualizamos el contenido alternativo
          };
        }
        return alt;
      });

      setFormData({
        ...formData,
        alternative_articles: updatedAlternatives, // Actualizamos la lista de artículos alternativos
      });
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch('/updateArticles', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...selectedArticle, ...formData }),
      });
      if (response.ok) {
        setIsPopupVisible(false);
        setSelectedLegalEntity('default');
        reloadData();
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      alert('Error al actualizar el artículo.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value, // Aquí se asegura que `subcategory_id` se actualice correctamente
    }));
  };

  const handleEditClick = async (item) => {
    try {
      const response = await fetch(`/articleDetails/${item.article_id}`);
      const data = await response.json();
      const article = data.articleDetails[0];

      // Validación para asegurarnos de que `alternative_articles` es un array
      let mappedAlternatives = [];
      if (article.alternative_articles) {
        // Si viene en formato string, parsearlo
        const alternatives =
          typeof article.alternative_articles === 'string' ? JSON.parse(article.alternative_articles) : article.alternative_articles;

        mappedAlternatives = alternatives.map((alt) => ({
          legal_entity_id: alt.legal_entity_id,
          alternative_content: alt.alternative_content || '',
        }));
      }

      setFormData({
        article_title: article.article_title,
        article_content: article.article_content,
        article_is_active: article.article_is_active,
        alternative_articles: mappedAlternatives,
        last_modifier_id: userInfoSSFF.userInfoSSFF.username,
        last_modification_date: currentDate,
      });

      setSelectedArticle(article);
      setIsEditMode(true);
      setIsPopupVisible(true);
      setSelectedLegalEntity('default');
    } catch (error) {
      console.error('Error fetching article details:', error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setSelectedArticle(null);
    setIsEditMode(false);
    setIsAddPopupVisible(false);
    setIsRelatedContentPopupVisible(false);
  };

  const getLegalEntities = async () => {
    try {
      const response = await fetch('/legalEntities', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLegalEntities(data.entitiesData);
      } else {
        console.error('Error en la respuesta:', response.statusText);
        alert('Error al obtener las entidades legales.');
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      alert('Error al realizar la solicitud para obtener las entidades legales.');
    }
  };

  const handleAddClick = () => {
    setIsAddPopupVisible(true);
    setFormData({
      article_title: '',
      is_active: false,
      article_content: '',
      subcategory_id: '',
      creator_id: userInfoSSFF.userInfoSSFF.username,
      creation_date: currentDate,
    });
  };
  const handleRelatedContent = () => {
    setIsRelatedContentPopupVisible(true);
    getLegalEntities();
    setFormData({
      article_id: '',
      legal_entity_id: '',
      article_content: '',
      creator_id: userInfoSSFF.userInfoSSFF.username,
      creation_date: currentDate,
    });
  };

  const handleAddArticle = async () => {
    try {
      const response = await fetch('/addArticle', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Enviar todos los datos, incluida la imagen en base64
      });
      if (response.ok) {
        setIsAddPopupVisible(false);
        reloadData(); // Recargar datos después de añadir
      } else {
        alert('Error al añadir el artículo.');
      }
    } catch (error) {
      console.error('Error al añadir el artículo:', error);
      alert('Error al añadir el artículo.');
    }
  };

  const handleAddRelatedContent = async () => {
    try {
      const response = await fetch('/addRelatedContent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Enviar todos los datos, incluida la imagen en base64
      });
      if (response.ok) {
        setIsRelatedContentPopupVisible(false);
        reloadData(); // Recargar datos después de añadir
      } else {
        alert('Error al añadir el contenido relacionado.');
      }
    } catch (error) {
      console.error('Error al añadir el contenido relacionado:', error);
      alert('Error al añadir el contenido relacionado.');
    }
  };

  // Calcula el índice del primer y último artículo de la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Obtener solo los artículos que corresponden a la página actual
  const currentItems = filteredItems.sort((a, b) => a.article_id - b.article_id).slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const maxPagesToShow = 5; // Número máximo de páginas visibles
  let pageNumbers = [];

  if (totalPages <= maxPagesToShow) {
    // Si el número total de páginas es menor que el máximo, muestra todas
    pageNumbers = [...Array(totalPages).keys()].map((i) => i + 1);
  } else {
    // Si el número total de páginas es mayor, muestra una serie de páginas con puntos suspensivos
    if (currentPage <= 3) {
      pageNumbers = [1, 2, 3, 4, '...', totalPages];
    } else if (currentPage >= totalPages - 2) {
      pageNumbers = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } else {
      pageNumbers = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          padding: '2em',
          height: '80px',
          gap: '1em',
          alignItems: 'center',
          justifyContent: 'space-between',
          alignContent: 'left',
          width: '100%',
        }}
      >
        {/* Contenedor del filtro */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em', height: '60px', gap: '1em' }}>
          <img
            src={filterIcon}
            alt='Toggle Filter'
            onClick={filterToggle}
            style={{
              border: 'none',
              marginTop: '1em',
              cursor: 'pointer',
              height: '18px',
            }}
          />
          <div className={`filter-container ${filterVisible ? 'visible' : 'hidden'}`}>
            <select className='form-select subcategoriesSelect' value={filterKey} onChange={(e) => setFilterKey(e.target.value)}>
              {filterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              type='text'
              className='form-control subcategoriesInput'
              placeholder='Buscar...'
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '2em',
            alignItems: 'center',
          }}
        >
          <button type='button' className='btn btn-outline-primary' onClick={handleAddClick}>
            Añadir Nuevo Artículo
          </button>
          <button type='button' className='btn btn-outline-primary' onClick={handleRelatedContent}>
            Nuevo Contenido Relacionado
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className='spinner-box'>
          <div className='pulse-container'>
            <div className='pulse-bubble pulse-bubble-1'></div>
            <div className='pulse-bubble pulse-bubble-2'></div>
            <div className='pulse-bubble pulse-bubble-3'></div>
          </div>
        </div>
      ) : filteredItems.length > 0 ? (
        <>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col' style={{ width: '5%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                  Id
                </th>
                <th scope='col' style={{ width: '40%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                  Nombre Artículo
                </th>
                <th scope='col' style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>
                  Estado
                </th>
                <th style={{ width: '5%' }} scope='col'></th>
                <th style={{ width: '5%' }} scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: '5%', textAlign: 'start', paddingLeft: '2em' }}>{item.article_id}</td>
                  <td style={{ width: '40%', textAlign: 'start', paddingLeft: '2em' }}>{item.article_title}</td>
                  <td style={{ textAlign: 'center' }}>
                    <button
                      style={{
                        fontSize: '11px',
                        padding: '3px 5px',
                        borderRadius: '5px',
                        border: item.article_is_active ? '1px solid #06f' : '1px solid grey',
                        backgroundColor: item.article_is_active ? '#06f' : 'white',
                        color: item.article_is_active ? 'white' : 'black',
                        width: '90%',
                        cursor: 'auto',
                      }}
                    >
                      {item.article_is_active ? 'Activo' : 'Desactivado'}
                    </button>
                  </td>
                  <td style={{ width: '5%' }}>
                    <img src={verIcon} alt='Ver' className='itemList__icon' onClick={() => handleViewClick(item)} />
                  </td>
                  <td style={{ width: '5%' }}>
                    <img src={editIcon} alt='Editar' className='itemList__icon' onClick={() => handleEditClick(item)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='adminPagination'>
            {pageNumbers.map((pageNumber, index) =>
              pageNumber === '...' ? (
                <span key={index} className='dots'>
                  ...
                </span>
              ) : (
                <button key={index} onClick={() => handlePageChange(pageNumber)} disabled={pageNumber === currentPage}>
                  {pageNumber}
                </button>
              )
            )}
          </div>
        </>
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '40%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Nombre Artículo
              </th>
              <th scope='col' style={{ width: '25%', textAlign: 'start', fontWeight: 'bold' }}>
                Subcategoría
              </th>
              <th scope='col' style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>
                Estado
              </th>
              <th style={{ width: '5%' }} scope='col'></th>
              <th style={{ width: '5%' }} scope='col'></th>
              <th style={{ width: '5%' }} scope='col'></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      )}
      {isPopupVisible && selectedArticle && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button
                  onClick={handleClosePopup}
                  style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em' }}
                >
                  &times;
                </button>
              </div>
              <div className='catPopup__infoContainer'>
                {isEditMode ? (
                  <form>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                      <div style={{ display: 'flex', gap: '1em' }}>
                        <div className='mb-3' style={{ width: '50%' }}>
                          <label htmlFor='articleStatus' className='form-label'>
                            <strong>Estado:</strong>
                          </label>
                          <select
                            className='form-select'
                            id='articleStatus'
                            name='article_is_active'
                            value={formData.article_is_active}
                            onChange={handleInputChange}
                          >
                            <option value='true'>Activo</option>
                            <option value='false'>Desactivado</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <label htmlFor='articleName' className='form-label'>
                          <strong>Título del artículo:</strong>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='articleName'
                          name='article_title'
                          value={formData.article_title}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <strong>Seleccionar contenido:</strong>
                        <div style={{ display: 'flex', width: '100%', gap: '1em' }}>
                          <button
                            type='button'
                            onClick={() => setSelectedLegalEntity('default')}
                            className={`contentButton ${selectedLegalEntity === 'default' ? 'selected' : ''}`}
                          >
                            Contenido por defecto
                          </button>
                          {(formData.alternative_articles || []).map((alt, idx) => (
                            <button
                              key={idx}
                              type='button'
                              onClick={() => {
                                setSelectedLegalEntity(alt.legal_entity_id);
                              }}
                              className={`contentButton ${selectedLegalEntity === alt.legal_entity_id ? 'selected' : ''}`}
                            >
                              Jurídica {alt.legal_entity_id}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className='mb-3' style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                        <p>
                          <strong>Contenido del artículo: </strong>
                        </p>
                        <ReactQuill
                          theme='snow'
                          value={
                            selectedLegalEntity === 'default'
                              ? formData.article_content
                              : formData.alternative_articles.filter((x) => x.legal_entity_id === selectedLegalEntity)[0]?.alternative_content
                          }
                          onChange={handleContentChange}
                          modules={modules}
                        />
                      </div>
                    </div>
                    <button type='button' className='btn btn-primary' onClick={handleSaveChanges}>
                      Guardar cambios
                    </button>
                  </form>
                ) : (
                  <>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '1em' }}>
                      <div style={{ display: 'flex', width: '100%', gap: '3em' }}>
                        <p>
                          <strong>Subcategoría a la que pertenece:</strong> {selectedArticle.subcategory_name}
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>Título del artículo: </strong>
                          {selectedArticle.article_title}
                        </p>
                      </div>
                      <div>
                        <strong>Seleccionar contenido:</strong>
                        <div style={{ display: 'flex', width: '100%', gap: '1em' }}>
                          <button
                            type='button'
                            onClick={() => setSelectedLegalEntity('default')}
                            className={`contentButton ${selectedLegalEntity === 'default' ? 'selected' : ''}`}
                          >
                            Contenido por defecto
                          </button>
                          {selectedArticle.alternative_articles.map((alt, idx) => (
                            <button
                              key={idx}
                              type='button'
                              onClick={() => {
                                setSelectedLegalEntity(alt.legal_entity_id);
                              }}
                              className={`contentButton ${selectedLegalEntity === alt.legal_entity_id ? 'selected' : ''}`}
                            >
                              Jurídica {alt.legal_entity_id}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div
                        className='BD_content'
                        dangerouslySetInnerHTML={{
                          __html:
                            selectedLegalEntity === 'default'
                              ? selectedArticle.article_content
                              : selectedArticle.alternative_articles.filter((x) => x.legal_entity_id === selectedLegalEntity)[0]?.alternative_content,
                        }}
                      ></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isAddPopupVisible && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button onClick={handleClosePopup} style={{ float: 'right', border: 'none', background: 'none', fontSize: '1.5em' }}>
                  &times;
                </button>
                <h5>Añadir Nuevo Artículo</h5>
              </div>
              <form style={{ width: '80%' }}>
                <div className='mb-3'>
                  <label htmlFor='article_title' className='form-label'>
                    <strong>Título del artículo:</strong>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='article_title'
                    name='article_title'
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ display: 'flex', width: '100%', gap: '1em', flexDirection: 'column' }} className='mb-3'>
                  <div style={{ display: 'flex', width: '100%', gap: '2em' }}>
                    <div className='mb-3' style={{ width: '50%' }}>
                      <label htmlFor='articleStatus' className='form-label'>
                        <strong>Estado:</strong>
                      </label>
                      <select className='form-select' id='articleStatus' name='is_active' value={formData.is_active} onChange={handleInputChange}>
                        <option value='true'>Activo</option>
                        <option value='false'>Desactivado</option>
                      </select>
                    </div>
                    <div className='mb-3' style={{ width: '50%' }}>
                      <label htmlFor='subcategory' className='form-label'>
                        <strong>Subcategoría a la que pertenecerá:</strong>
                      </label>
                      <select
                        className='form-select'
                        id='subcategory'
                        name='subcategory_id'
                        value={formData.subcategory_id || ''}
                        onChange={handleInputChange}
                      >
                        <option value=''>Seleccione una subcategoría</option>
                        {subcategories.map((subcategory, index) => (
                          <option key={`${subcategory.id}-${index}`} value={subcategory.id}>
                            {subcategory.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='richTextEditor' className='form-label'>
                      <strong>Contenido del artículo:</strong>
                    </label>
                    <ReactQuill
                      theme='snow'
                      value={formData.article_content}
                      onChange={(updatedContent) => handleInputChange({ target: { name: 'article_content', value: updatedContent } })}
                      modules={modules}
                    />
                  </div>
                </div>
                <button type='button' className='btn btn-primary' onClick={handleAddArticle}>
                  Añadir Artículo
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {isRelatedContentPopupVisible && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button onClick={handleClosePopup} style={{ float: 'right', border: 'none', background: 'none', fontSize: '1.5em' }}>
                  &times;
                </button>
                <h5>Añadir Nuevo Contenido Relacionado</h5>
              </div>
              <form style={{ width: '80%' }}>
                <div className='mb-3'>
                  <p className='mb-3 text-primary'>¿Sobre qué artículo quieres añadir contenido relacionado?</p>
                  <div style={{ display: 'flex', gap: '3em' }}>
                    <label htmlFor='article_title' className='form-label'>
                      <strong>Id del artículo:</strong>
                      <input
                        type='number'
                        className='form-control'
                        id='article_id'
                        name='article_id'
                        value={formData.article_id}
                        onChange={handleInputChange}
                      />
                    </label>
                    <label htmlFor='legalEntities' className='form-label'>
                      <strong>Jurídica a asignar:</strong>
                      <select
                        className='form-select'
                        id='legalEntities'
                        name='legal_entity_id'
                        value={formData.legal_entity_id}
                        onChange={handleInputChange}
                      >
                        {/* Opciones dinámicas */}
                        <option value=''>Jurídica</option>
                        {legalEntities.map((legalEntity) => (
                          <option key={legalEntity.legal_entity_id} value={legalEntity.legal_entity_id}>
                            {legalEntity.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <div style={{ display: 'flex', width: '100%', gap: '1em', flexDirection: 'column' }} className='mb-3'>
                  {/* Editor enriquecido */}
                  <div className='mb-3'>
                    <label htmlFor='richTextEditor' className='form-label'>
                      <strong>Contenido del artículo relacionado:</strong>
                    </label>
                    <ReactQuill
                      theme='snow'
                      value={formData.article_content}
                      onChange={(updatedContent) => handleInputChange({ target: { name: 'article_content', value: updatedContent } })}
                      modules={modules}
                    />
                  </div>
                </div>
                <button type='button' className='btn btn-primary' onClick={handleAddRelatedContent}>
                  Añadir contenido relacionado
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleList;
