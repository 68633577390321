import React, { useState } from 'react';
import '../../../../styles/change/employeeSearch.css';

// Componente para buscar empleados y mostrar detalles.
const EmployeeSearch = ({
  managerEmployees, // Array de empleados
  setSelectedUser, // Función para seleccionar un usuario
  setEmployeeName, // Función para establecer el nombre del empleado
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({
    name: '',
    email: '',
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filtrar empleados según el término ingresado
    if (value.trim()) {
      const matches = managerEmployees.filter((employee) => employee.defaultFullName.toLowerCase().includes(value.toLowerCase()));
      setFilteredEmployees(matches);

      // Verificar si el valor coincide exactamente con algún defaultFullName
      const exactMatch = matches.find((employee) => employee.defaultFullName.toLowerCase() === value.toLowerCase());

      if (exactMatch) {
        handleSuggestionClick(exactMatch); // Autocompletar con la coincidencia exacta
      }
    } else {
      setFilteredEmployees([]);
      setSelectedEmployee({ name: '', email: '' }); // Restablecer campos
    }
  };

  const handleSuggestionClick = (employee) => {
    setSearchTerm(employee.defaultFullName); // Actualizar el input con el nombre completo
    setSelectedUser(employee); // Guardar el empleado seleccionado
    setEmployeeName(employee.defaultFullName); // Actualizar el nombre en el formulario
    setSelectedEmployee({
      name: employee.defaultFullName,
      email: employee.email,
    }); // Actualizar detalles del empleado seleccionado
    setFilteredEmployees([]); // Limpiar las sugerencias
  };

  return (
    <div className='formArticleCheckboxGroup_change'>
      <label htmlFor='employeeName'>Nombre del Empleado:</label>
      <div className='autocomplete-container'>
        {/* Input para búsqueda */}
        <input
          type='text'
          id='employeeName'
          value={searchTerm}
          onChange={handleInputChange}
          placeholder='Escribe el nombre del empleado'
          className='formJiraChangeInputText'
        />

        {/* Lista de sugerencias */}
        {filteredEmployees.length > 0 && (
          <ul className='autocomplete-suggestions'>
            {filteredEmployees.map((employee) => (
              <li key={employee.userId} onClick={() => handleSuggestionClick(employee)} className='autocomplete-suggestion'>
                {employee.defaultFullName}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Detalles del empleado seleccionado */}
      <div className='employeeDetails'>
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel_short' htmlFor='employeeName'>
            Nombre:
          </label>
          <input type='text' className='formJiraChangeInputText_short' value={selectedEmployee.name} readOnly />
        </div>
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel_short' htmlFor='employeeEmail'>
            Email:
          </label>
          <input type='text' className='formJiraChangeInputText_short' value={selectedEmployee.email} readOnly />
        </div>
      </div>
    </div>
  );
};

export default EmployeeSearch;
