import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import { createJiraTicket, updateJiraTicket } from '../../../services/apiJira.js';
import PersonalInfoSection from './personalSection.js';
import TerminationDetailsSection from './detailsSection.js';
import YesNoSection from './yesNoSection.js';
import FormActions from './formActions.js';
import PhoneNum from './phoneNum.js';
import '../../styles/terminate/formTerminate.css';
import { urlToBase64, readFileAsBase64, sanitize, base64ToBlob } from '../../../utils/utils';

import CryptoJS from 'crypto-js';

const secretKey = 'your-secret-key';

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

const TerminationRequest = ({ ticketData, userInfoSSFF }) => {
  //FORM DATA
  const [employeeName, setEmployeeName] = useState(`${userInfoSSFF.firstName} ${userInfoSSFF.lastName}`);
  const [employeeEmail, setEmployeeEmail] = useState(userInfoSSFF.username);
  const [employeeLEntity, setEmployeeLEntity] = useState(userInfoSSFF.custom03);
  const [employeeRegistrationId, setEmployeeRegistrationId] = useState(userInfoSSFF.empInfo.personNav.personalInfoNav.results[0].customString5);
  const [employeeManagerEmail, setEmployeeManagerEmail] = useState(userInfoSSFF.manager.username);
  const [terminationDate, setTerminationDate] = useState('');
  const [terminationType, setTerminationType] = useState('');
  const [transferToGroup, setTransferToGroup] = useState('No');
  const [takenVacation, setTakenVacation] = useState('');
  const [actionPlan, setActionPlan] = useState('No');
  const [phoneLine, setPhoneLine] = useState('No');
  const [extraInfo, setExtraInfo] = useState('');
  const [duration, setDuration] = useState('');
  const [durationUnit, setDurationUnit] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [showOtherSections, setShowOtherSections] = useState(true);
  const [files, setFiles] = useState([]);
  const registrationId = userInfoSSFF?.empInfo.personNav.personalInfoNav.results[0].customString5;

  //SHOW REQUESTED FIELDS
  const [showDurationRequired, setShowDurationRequired] = useState(false);
  const [showTerminationDateRequired, setShowTerminationDateRequired] = useState(false);
  const [showTerminationDateError, setShowTerminationDateError] = useState(false);
  const [showTerminationTypeRequired, setShowTerminationTypeRequired] = useState(false);
  const [showTransferToGroup, setShowTransferToGroup] = useState(false);
  const [showTransferToGroupRequired, setShowTransferToGroupRequired] = useState(false);
  const [showTakenVacation, setshowTakenVacation] = useState(false);
  const [showTakenVacationRequired, setshowTakenVacationRequired] = useState(false);
  const [showTakenVacationWarning, setshowTakenVacationWarning] = useState(false);
  const [showActionPlan, setShowActionPlan] = useState(false);
  const [showPhoneLine, setShowPhoneLine] = useState(false);
  const [showPhoneLineRequired, setShowPhoneLineRequired] = useState(false);
  const [showPenalizationDisclaimer, setShowPenalizationDisclaimer] = useState(false);

  //SEND MESSAGE
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ticketDataExists, setTicketDataExists] = useState(false);
  const [ticketId, setTicketId] = useState('');

  // Define la función para actualizar employeeLEntity
  const updateEmployeeLEntity = (companyName) => {
    setEmployeeLEntity(companyName);
  };

  useEffect(() => {
    if (ticketData) {
      let durationValue = ticketData.duration || '';

      if (durationValue > 12) {
        setDuration(durationValue / 12);
        setDurationUnit('años');
      } else {
        setDuration(durationValue);
        setDurationUnit('meses');
      }
      // Inicializa valores de ticketData
      setEmployeeName(ticketData.employeeName || '');
      setEmployeeEmail(ticketData.employeeEmail || '');
      setEmployeeLEntity(ticketData.employeeLEntity || '');
      setEmployeeRegistrationId(ticketData.employeeRegistrationId || '');
      setEmployeeManagerEmail(ticketData.employeeManagerEmail || '');
      setTerminationDate(ticketData.terminationDate || '');
      setTerminationType(ticketData.terminationType || '');
      setTransferToGroup(ticketData.transferToGroup || '');
      setTakenVacation(ticketData.takenVacation || '');
      setActionPlan(ticketData.actionPlan || '');
      setPhoneLine(ticketData.phoneLine || '');
      setShowTransferToGroup(!!ticketData.transferToGroup);
      setshowTakenVacation(!!ticketData.takenVacation);
      setShowActionPlan(!!ticketData.actionPlan);
      setShowPhoneLine(!!ticketData.phoneLine);
      setTicketId(ticketData.id);

      // Manejo de archivos adjuntos
      if (ticketData.filesAttached) {
        setFiles(ticketData.filesAttached);
      }

      // Lógica para el manejo de otros grupos y empresas
      const predefinedGroups = [
        'TEST, SA - Telefonica SA',
        'TSA - Telefonica SA',
        'Telefónica I+D España',
        'Telefonica Global Solutions (TGS)',
        'TELXIUS CABLE ESPAÑA',
        'TELXIUS TELECOM',
        'TCE',
        'T. HISPAM',
      ];

      if (predefinedGroups.includes(ticketData.transferToGroup)) {
        setTransferToGroup(ticketData.transferToGroup || '');
        setOtherCompany('');
      } else {
        setTransferToGroup('Otros');
        setOtherCompany(ticketData.transferToGroup || '');
      }

      // Establecer mensaje adicional si hay duración
      if (ticketData.duration) {
        setExtraInfo('Por favor, indique la duración en días/mes/años?:');
      }
      setTicketDataExists(true);
    }
  }, [ticketData]);

  useEffect(() => {
    const areFieldsValid =
      terminationDate.trim() !== '' &&
      terminationType.trim() !== '' &&
      !showTerminationDateError &&
      (!extraInfo || (!isNaN(parseFloat(duration)) && isFinite(duration))) &&
      (!showTransferToGroup || transferToGroup.trim() !== '' || (transferToGroup === 'Otros' && otherCompany.trim() !== '' && showTransferToGroup)) &&
      (!showTakenVacation || (!isNaN(parseFloat(takenVacation)) && isFinite(takenVacation))) &&
      (!showPhoneLine || phoneLine.trim() !== '');

    setIsButtonEnabled(areFieldsValid);
  }, [
    terminationDate,
    terminationType,
    extraInfo,
    duration,
    showActionPlan,
    actionPlan,
    showTakenVacation,
    takenVacation,
    showPhoneLine,
    phoneLine,
    showTransferToGroup,
    transferToGroup,
    otherCompany,
    showTerminationDateError,
  ]);

  //Año en curso
  const today = new Date();
  const currentYear = today.getFullYear();

  // Manejo de cambio de fecha de baja
  const handleTerminationDateChange = (e) => {
    const selectedDate = e.target.value;
    setTerminationDate(selectedDate);
    setShowTerminationDateRequired(false);

    const futureDate15Days = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
    const selectedDateObject = new Date(selectedDate);
    if (selectedDateObject < futureDate15Days) {
      setShowTerminationDateError(false);
      setShowPenalizationDisclaimer(true);
    } else {
      setShowTerminationDateError(false);
      setShowPenalizationDisclaimer(false);
    }
  };

  const handleTerminationTypeChange = (e) => {
    const type = e.target.value;
    setTerminationType(type);
    setShowTerminationTypeRequired(false);

    // Resetea la duración si está en el array de tipos específicos
    if (['Excedencia por cuidado de menor', 'Excedencia por cuidado de familiar dependiente', 'Excedencia voluntaria'].includes(type)) {
      setExtraInfo('Por favor, indique la duración de la excedencia con un número y seleccione meses o años:');
      setDuration(''); // Reseteamos la duración a vacía
    } else if (type === 'Permisos no retribuidos') {
      setExtraInfo('Por favor, indique la duración del permiso con un número y seleccione meses o años:');
      setDuration(''); // También reseteamos la duración
    } else {
      setExtraInfo('');
      setDuration(999); // En los demás casos, se establece duración en 999
    }
  };

  // Manejo de cambio de jurídica/grupo
  const handleTransferToGroupChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === 'Otros') {
      setTransferToGroup('Otros');
      setShowOtherSections(true); // Mantiene las otras secciones visibles si es 'Otros'
    } else {
      setTransferToGroup(selectedValue);
      setOtherCompany(''); // Reinicia el valor de "otherCompany" si no es 'Otros'

      // Si el valor seleccionado no es 'Otros', oculta las otras secciones
      if (
        selectedValue === 'Telefonica Compras Electronicas' ||
        selectedValue === 'Telefonica Cybersecurity & Cloud Tech' ||
        selectedValue === 'Telefonica De España' ||
        selectedValue === 'Telefonica Global Solutions' ||
        selectedValue === 'Telefonica Hispanoamerica' ||
        selectedValue === 'Telefonica Innovacion Digital' ||
        selectedValue === 'Telefonica Iot & Big Data Tech' ||
        selectedValue === 'Telefonica Moviles España' ||
        selectedValue === 'Telefonica Open Innovation' ||
        selectedValue === 'Telefonica S.A.' ||
        selectedValue === 'Telefónica Soluciones De Criptografía' ||
        selectedValue === 'Telxius Cable España' ||
        selectedValue === 'Telxius Telecom'
      ) {
        setShowOtherSections(false); // Oculta las secciones si se selecciona otra empresa del grupo
      } else {
        setShowOtherSections(true); // Muestra las otras secciones si el valor no es un traspaso a otra empresa del grupo
      }
    }
  };

  // Manejo de cambio de días de vacaciones pendientes
  const handletakenVacationChange = (e) => {
    const value = e.target.value;

    // Verifica si el valor es vacío, un número positivo, o no es un número
    if (value === '') {
      setTakenVacation(''); // Permite que el valor sea vacío
      setshowTakenVacationWarning(false); // Oculta el warning si está vacío
    } else if (isNaN(value) || Number(value) < 0) {
      setshowTakenVacationWarning(true); // Muestra el warning
      setTakenVacation(''); // Opcional: limpiar el valor si no es válido
    } else {
      setTakenVacation(value);
      setshowTakenVacationWarning(false); // Oculta el warning si es válido
      setshowTakenVacationRequired(false); // Si era requerido y ya se introdujo un valor, ocultarlo
    }
  };

  //Manejo de cambio de número de teléfono
  const handlePhoneLineChange = (e) => {
    setPhoneLine(e.target.value);
    setShowPhoneLineRequired(false);
  };
  const handleDurationChange = (e) => {
    const value = e.target.value;
    // Almacena el valor original directamente
    setDuration(value);
    setShowDurationRequired(false);
  };

  const handleDurationUnitChange = (e) => {
    const selectedUnit = e.target.value;
    setDurationUnit(selectedUnit);
  };
  const handleOtherCompanyChange = (event) => {
    const value = event.target.value;
    setOtherCompany(value);
  };

  //Manjejo de evento onBlur en el input de "Otros"
  const handleOtherCompanyBlur = () => {
    if (otherCompany.trim() !== '') {
      setTransferToGroup(otherCompany);
    }
  };

  //Manejo de los radio si/no
  const handleYesNoChange = (field) => (e) => {
    const isChecked = e.target.value === 'yes';

    if (field === 'transferToGroup') {
      setShowTransferToGroup(isChecked);
      if (!isChecked) {
        setTransferToGroup('No');
        setOtherCompany('');
        setShowOtherSections(true);
      }
    } else if (field === 'takenVacation') {
      setshowTakenVacation(isChecked);
      if (!isChecked) {
        setTakenVacation('No');
      }
    } else if (field === 'actionPlan') {
      setShowActionPlan(isChecked);
      setActionPlan('Sí');
      if (!isChecked) {
        setActionPlan('No');
      }
    } else if (field === 'phoneLine') {
      setShowPhoneLine(isChecked);
      if (!isChecked) {
        setPhoneLine('No');
      }
    }
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    if (terminationDate === '') {
      setShowTerminationDateRequired(true);
    }
    if (terminationType === '') {
      setShowTerminationTypeRequired(true);
    }
    if (transferToGroup === '' && showTransferToGroup) {
      setShowTransferToGroupRequired(true);
    }
    if (transferToGroup === 'Otros' && otherCompany.trim() === '' && showTransferToGroup) {
      setShowTransferToGroupRequired(true);
    }
    if (takenVacation === '' && showTakenVacation) {
      setshowTakenVacationRequired(true);
    }
    if (phoneLine === '' && showPhoneLine) {
      setShowPhoneLineRequired(true);
    }
    if (duration === '' && extraInfo) {
      setShowDurationRequired(true);
    }

    if (showTerminationDateError) {
      return;
    } else if (isButtonEnabled) {
      try {
        setIsSubmitting(true);

        let actualDuration = duration === '' ? 999 : parseFloat(duration);

        if (durationUnit === 'años') {
          actualDuration *= 12;
        }

        const sanitize = (value) => DOMpurify.sanitize(value);

        let filesAttached = []; // Inicialmente vacío o puedes tener archivos previos adjuntados aquí

        // Si ya tenías archivos adjuntados, puedes cargarlos aquí en `filesAttached`

        if (files.length > 0) {
          // Recorremos todos los archivos para procesarlos
          const newFilesProcessed = await Promise.all(
            files.map(async (file, index) => {
              // Determina el número de archivo para asignar el nombre correspondiente.
              const nextFileNumber = filesAttached.length + index + 1; // Incrementa en 1 por cada archivo
              const paddedNumber = nextFileNumber.toString().padStart(2, '0'); // Asegura que el número tenga al menos dos dígitos

              // Extraemos la extensión del archivo original
              const fileExtension = file.name.split('.').pop(); // Obtiene la extensión después del último punto

              // Convertimos el archivo a base64
              const base64 = await readFileAsBase64(file);

              // Retornamos el archivo con nombre y contenido encriptado
              return {
                name: `documentacion_${paddedNumber}_${registrationId}.${fileExtension}`, // Concatenamos la extensión
                content: encryptValue(base64), // Encriptamos el contenido en base64
              };
            })
          );

          // Unimos los nuevos archivos procesados con los anteriores (si ya había archivos)
          filesAttached = [...filesAttached, ...newFilesProcessed];
        } else {
          console.log('No hay archivos para adjuntar.');
        }

        //Preparamos los campos a enviar al ticket y los encriptamos
        const fields = {
          employeeName: encryptValue(sanitize(employeeName)),
          employeeEmail: encryptValue(sanitize(employeeEmail)),
          employeeLEntity: encryptValue(sanitize(employeeLEntity)),
          employeeRegistrationId: encryptValue(sanitize(employeeRegistrationId)),
          employeeManagerEmail: encryptValue(sanitize(employeeManagerEmail)),
          terminationDate: encryptValue(sanitize(terminationDate.toString())),
          terminationType: encryptValue(sanitize(terminationType.toString())),
          transferToGroup: encryptValue(sanitize(transferToGroup)),
          takenVacation: encryptValue(sanitize(takenVacation)),
          actionPlan: encryptValue(sanitize(actionPlan.toString())),
          phoneLine: encryptValue(sanitize(phoneLine.toString())),
          duration: encryptValue(sanitize(actualDuration)),
          filesAttached: filesAttached,
        };

        let ticketData = {
          summary: `Baja`,
          issueType: 'Baja',
          priority: 'Medium',
        };

        //Renombramos al naming que espera recibir el ticket de Jira
        const customFields = {
          customfield_10318: fields.employeeName,
          customfield_10319: fields.employeeEmail,
          customfield_10322: fields.employeeLEntity,
          customfield_10353: fields.employeeRegistrationId,
          customfield_10330: fields.employeeManagerEmail,
          customfield_10310: fields.terminationDate,
          customfield_10312: fields.terminationType,
          customfield_10317: fields.transferToGroup,
          customfield_10316: fields.takenVacation,
          customfield_10363: fields.actionPlan,
          customfield_10321: fields.phoneLine,
          customfield_10320: fields.duration,
        };

        Object.keys(customFields).forEach((key) => {
          if (customFields[key] === '' || customFields[key] === 0) {
            delete customFields[key];
          }
        });

        ticketData = { ...ticketData, ...customFields };

        if (ticketDataExists) {
          // Asumiendo que ticketData tiene un id
          await updateJiraTicket(ticketData, ticketId, fields.filesAttached);
          alert('Ticket actualizado');
        } else {
          await createJiraTicket(ticketData, fields.filesAttached);
          setFeedbackPopup(true);
        }

        setTerminationDate('');
        setTerminationType('');
        setTransferToGroup('');
        setTakenVacation('');
        setActionPlan('');
        setPhoneLine('');
        setDuration('');
        setFiles([]);
        //setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  return (
    <section className='formJiraEnd_terminate'>
      <h1 className='formJiraEnd__title'>Solicitud de Baja voluntaria</h1>
      <form className='formJiraEnd__form' onSubmit={HandleSubmit}>
        <PersonalInfoSection userInfoSSFF={userInfoSSFF} ticketData={ticketData} updateEmployeeLEntity={updateEmployeeLEntity} />
        <TerminationDetailsSection
          terminationDate={terminationDate}
          onTerminationDateChange={handleTerminationDateChange}
          terminationType={terminationType}
          onTerminationTypeChange={handleTerminationTypeChange}
          extraInfo={extraInfo}
          duration={duration}
          durationUnit={durationUnit}
          onDurationChange={handleDurationChange}
          onDurationUnitChange={handleDurationUnitChange}
          showTerminationDateRequired={showTerminationDateRequired}
          showTerminationDateError={showTerminationDateError}
          showTerminationTypeRequired={showTerminationTypeRequired}
          showDurationRequired={showDurationRequired}
          showPenalizationDisclaimer={showPenalizationDisclaimer}
          userInfoSSFF={userInfoSSFF}
        />
        <YesNoSection
          label='¿Tu baja supone un traspaso a otra Empresa del Grupo Telefónica?'
          name='transferToGroup'
          value={showTransferToGroup ? 'yes' : 'no'}
          onChange={handleYesNoChange('transferToGroup')}
          showRequired={showTransferToGroupRequired}
          details={
            showTransferToGroup && (
              <div>
                <select
                  id='transferToGroupDetails'
                  className='formJiraEndSelect'
                  value={transferToGroup}
                  onChange={handleTransferToGroupChange}
                  required
                >
                  <option value='' disabled>
                    Selecciona la empresa
                  </option>
                  <option value='Telefonica Compras Electronicas'>Telefonica Compras Electronicas</option>
                  <option value='Telefonica Cybersecurity & Cloud Tech'>Telefonica Cybersecurity & Cloud Tech</option>
                  <option value='Telefonica De España'>Telefonica De España</option>
                  <option value='Telefonica Global Solutions'>Telefonica Global Solutions</option>
                  <option value='Telefonica Hispanoamerica'>Telefonica Hispanoamerica</option>
                  <option value='Telefonica Innovacion Digital'>Telefonica Innovacion Digital</option>
                  <option value='Telefonica Iot & Big Data Tech'>Telefonica Iot & Big Data Tech</option>
                  <option value='Telefonica Moviles España'>Telefonica Moviles España</option>
                  <option value='Telefonica Open Innovation'>Telefonica Open Innovation</option>
                  <option value='Telefonica S.A.'>Telefonica S.A.</option>
                  <option value='Telefónica Soluciones De Criptografía'>Telefónica Soluciones De Criptografía</option>
                  <option value='Telxius Cable España'>Telxius Cable España</option>
                  <option value='Telxius Telecom'>Telxius Telecom</option>
                  <option value='Otros'>Otros</option>
                </select>
              </div>
            )
          }
        />
        {/* Mostrar el campo de texto solo si se selecciona "Otros" */}
        {transferToGroup === 'Otros' && showTransferToGroup && (
          <div className='formJiraEndSection'>
            <label className='formJiraEndLabel'>
              Nombre de la otra jurídica o grupo:
              <input
                type='text'
                className='formJiraEndInputText_long'
                value={otherCompany}
                onChange={handleOtherCompanyChange}
                onBlur={handleOtherCompanyBlur}
              />
            </label>
          </div>
        )}
        {showOtherSections && (
          <>
            <div className='formJiraEndSection'>
              <label className='formJiraEndLabel'> ¿Cuántos días de vacaciones has disfrutado en {currentYear}?</label>
              <div>
                <input
                  type='number'
                  placeholder='Número de días'
                  className='formJiraEndInputText_short2'
                  value={takenVacation}
                  onChange={handletakenVacationChange}
                />
                {showTakenVacationWarning && <p className='formJiraEndLabel__required'>El valor introducido no tiene el formato adecuado.</p>}
              </div>
            </div>
            <YesNoSection
              label='¿Tienes algún plan de acciones o has participado en algún plan de acciones de empleado en estos últimos años?'
              name='actionPlan'
              value={showActionPlan ? 'yes' : 'no'}
              onChange={handleYesNoChange('actionPlan')}
              details={
                showActionPlan && (
                  <div className='formJiraEndInputText_long'>
                    <span>
                      En el caso de tener acciones de Telefónica, es necesario que te asegures de actualizar tu información de contacto en la
                      plataforma de EquatePlus con una dirección de email personal para que puedas seguir accediendo. Tienes toda la información
                      necesaria en el artículo de PeoplEX - ¿Cómo puedo actualizar mis datos de contacto en EquatePlus?
                    </span>
                  </div>
                )
              }
            />
            <YesNoSection
              label='¿Deseas quedarte con la línea de teléfono asignada?'
              name='phoneLine'
              value={showPhoneLine ? 'yes' : 'no'}
              onChange={handleYesNoChange('phoneLine')}
              showRequired={showPhoneLineRequired}
              details={showPhoneLine && <PhoneNum label='Número de teléfono' name='phoneLine' value={phoneLine} onChange={handlePhoneLineChange} />}
            />
          </>
        )}
        <FormActions
          isButtonEnabled={isButtonEnabled}
          isSubmitting={isSubmitting}
          feedbackPopup={feedbackPopup}
          messageSendingFailed={messageSendingFailed}
          handleCloseFeedbackPopup={handleCloseFeedbackPopup}
          files={files}
          setFiles={setFiles}
        />
      </form>
    </section>
  );
};

export default TerminationRequest;
