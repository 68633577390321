import { useState, useEffect } from 'react';
import '../styles/adminPanel.css';
import collapseIcon from '../../images/adminPanel/collapse.png';
import CategoryList from './categoryList';
import SubcategoryList from './subcategoryList';
import ArticleList from './articleList';
import HighlightedContentList from './HighlightedContentList';

const AdminPanel = (userInfoSSFF) => {
  const [activeSection, setActiveSection] = useState(1);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Estado para controlar el sidebar
  const [items, setItems] = useState([]); // Estado para almacenar los datos de cada sección
  const [isLoading, setIsLoading] = useState(false);

  const sections = [
    { id: 1, title: 'Categorías', endpoint: '/categories', component: CategoryList, dataKey: 'categoriesData' },
    { id: 2, title: 'Subcategorías', endpoint: '/subcategories', component: SubcategoryList, dataKey: 'subcategoriesData' },
    { id: 3, title: 'Artículos', endpoint: '/articles', component: ArticleList, dataKey: 'articlesData' },
    { id: 4, title: 'Contenido destacado', endpoint: '/highlightedArticles', component: HighlightedContentList, dataKey: 'highlightedData' },
  ];

  const currentDate = new Date().toISOString().split('T')[0];

  // Fetch data based on the active section
  const reloadData = () => {
    const currentSection = sections.find((section) => section.id === activeSection);
    if (currentSection?.endpoint) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(currentSection.endpoint);
          const data = await response.json();

          if (response.ok) {
            if (currentSection.endpoint === '/categories') {
              localStorage.setItem('categories', JSON.stringify(data.categoriesData));
              setItems(data[currentSection.dataKey] || []);
            } else if (currentSection.endpoint === '/subcategories') {
              // Mapear solo id y name
              const subcategoriesToStore = data.subcategoriesData.map((subcategory) => ({
                id: subcategory.subcategory_id,
                name: subcategory.subcategory_name,
              }));

              // Almacenar en localStorage
              localStorage.setItem('subcategories', JSON.stringify(subcategoriesToStore));
            }
            setItems(data[currentSection.dataKey] || []);
          } else {
            console.error('Error fetching data:', response.statusText);
            setItems([]);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setItems([]);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  };

  useEffect(() => {
    reloadData();
  }, [activeSection]);

  const CurrentComponent = sections.find((section) => section.id === activeSection)?.component;

  return (
    <div className='d-flex' style={{ height: '100vh' }}>
      {/* Sidebar */}
      <div
        className={`d-flex flex-column adminSidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}
        style={{
          width: isSidebarCollapsed ? '40px' : 'fit-content',
          backgroundColor: '#F5F5F5',
          padding: '3em 0',
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <div className='buttonContainer'>
          <img
            src={collapseIcon}
            alt='colapsar sección'
            className='toggle-btn'
            style={{
              transition: 'transform 0.3s ease-in-out',
              transform: isSidebarCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          />
        </div>
        <ul
          className='nav flex-column'
          style={{
            backgroundColor: '#F5F5F5',
            fontSize: '14px',
            gap: '1em',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {sections.map((section) => (
            <li
              key={section.id}
              className={`nav-item px-3 py-2 ${
                !isSidebarCollapsed && activeSection === section.id ? 'bg-white text-primary fw-bold' : 'text-primary'
              }`}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: !isSidebarCollapsed && '90%',
              }}
              onClick={() => setActiveSection(section.id)}
            >
              {isSidebarCollapsed ? '' : section.title}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className='flex-grow-1'>
        {CurrentComponent ? (
          <CurrentComponent items={items} isLoading={isLoading} reloadData={reloadData} userInfoSSFF={userInfoSSFF} currentDate={currentDate} />
        ) : (
          <p>{sections.find((section) => section.id === activeSection)?.content}</p>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
