import React, { useEffect, useState } from 'react';
import elipse242 from '../../../../../../images/ellipse242.png';
import { validateFields } from '../formValidate';

export const SalaryChange = ({
  salaryChange,
  currency,
  bonusOrIncentives,
  incentivesAmount,
  salaryReason,
  setSalaryChange,
  setCurrency,
  setBonusOrIncentives,
  setIncentivesAmount,
  setSalaryReason,
  errors,
  setErrors,
}) => {
  const [showWarning, setShowWarning] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      salaryChange,
      currency,
      bonusOrIncentives,
      incentivesAmount,
      salaryReason,
    });
  }, []);

  useEffect(() => {
    const fields = {
      salaryChange,
      currency,
      bonusOrIncentives,
      incentivesAmount,
      salaryReason,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [salaryChange, currency, bonusOrIncentives, incentivesAmount, salaryReason]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldName === 'salaryReason' || fieldName === 'currency' || fieldName === 'salaryChange') {
      return fieldValue && fieldValue === initialValues[fieldName]
        ? 'formJiraChangeInputText prefilledBorder'
        : errors[fieldName]
        ? 'formJiraChangeInputText errorBorder'
        : 'formJiraChangeInputText';
    }

    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText_short prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeInputText_short errorBorder' : 'formJiraChangeInputText_short';
  };

  const radioClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
  };

  const handleRadioChange = (currentValue, setter, selectedValue) => {
    if (currentValue === selectedValue) {
      setter(''); // Deseleccionar si se vuelve a hacer clic
    } else {
      setter(selectedValue); // Seleccionar si es un valor diferente
    }
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios salariales</div>
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.salaryChange && '*'}Nuevo salario fijo anual:
          <input
            type='number'
            className={getInputClass(salaryChange, 'salaryChange')}
            value={salaryChange}
            onChange={(e) => setSalaryChange(e.target.value)}
            placeholder='Salario anual...'
            min={0}
          />
        </label>
        <label className='formJiraChangeLabel_bothIndividual'>
          {errors.currency && '*'}Moneda:
          <select className={getInputClass(currency, 'currency')} value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value='' disabled>
              Seleccione
            </option>
            <option value='Euro (€)'>EUR - Euro</option>
          </select>
        </label>
      </div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.bonusOrIncentives && '*'}Variable:
        </label>
        <div className='formJiraChangeRadioGroup'>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='bonusOrIncentives'
              className={radioClass(bonusOrIncentives, 'bonusOrIncentives')}
              value='Bonus'
              checked={bonusOrIncentives === 'Bonus'}
              onChange={() => handleRadioChange(bonusOrIncentives, setBonusOrIncentives, 'Bonus')}
            />
            Bonus
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='bonusOrIncentives'
              className={radioClass(bonusOrIncentives, 'bonusOrIncentives')}
              value='Incentivos comerciales'
              checked={bonusOrIncentives === 'Incentivos comerciales'}
              onChange={() => handleRadioChange(bonusOrIncentives, setBonusOrIncentives, 'Incentivos comerciales')}
            />
            Incentivos Comerciales
          </label>
        </div>

        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel_short'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            {errors.incentivesAmount && '*'}Importe:
          </label>
          <input
            type='number'
            className={getInputClass(incentivesAmount, 'amount')}
            value={incentivesAmount}
            onChange={(e) => setIncentivesAmount(e.target.value)}
            placeholder='Importe...'
            min={0}
          />
        </div>
      </div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.salaryReason && '*'}¿Cuál es el motivo del cambio salarial?
          <input
            type='text'
            className={getInputClass(salaryReason, 'salaryReason')}
            value={salaryReason}
            onChange={(e) => setSalaryReason(e.target.value)}
            placeholder='Motivo del cambio...'
          />
        </label>
      </div>
    </div>
  );
};
