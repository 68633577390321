const GourmetCard = () => {
  return (
    <>
      <iframe
        width='100%'
        height='100%'
        src='https://forms.office.com/e/wksD4P4Jbn?embed=true'
        frameborder='0'
        marginwidth='0'
        marginheight='0'
        style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        msallowfullscreen
        title='gourmetCardForm'
      />
    </>
  );
};

export default GourmetCard;
