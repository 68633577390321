import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import terminationIcon from '../../images/jira/termination.svg';
import changeManagerIcon from '../../images/jira/changemanager.svg';
import changePeopleIcon from '../../images/jira/changePeople.svg';
import changeViewnextIcon from '../../images/jira/changeViewnext.svg';
import newEmpManagerIcon from '../../images/jira/newEmpManager.svg';
import manageRequestIcon from '../../images/jira/gestionsolicitudes.png';
import arrowIcon from '../../images/folder/arrow.svg';
import '../styles/jiraPanel.css';

const MainPanelJira = ({ userInfoSSFF, idToken, managerEmployees, setManagerEmployees }) => {
  const isManager = userInfoSSFF?.custom15 === 'Y';
  const isViewnext = userInfoSSFF?.division === 'VIEWNEXT';
  const isLifeCycleExperience = userInfoSSFF?.department === 'LIFECYCLE EXPERIENCE (DE107212)';
  const isPeopleExperience = userInfoSSFF?.department === 'PEOPLE EXPERIENCE (DE101313)';

  const validationList = [
    'pablo.navarrofh@telefonica.com',
    'laura.guijarromolina@telefonica.com',
    'carmen.arevalo@telefonica.com',
    'mariajulia.reysanchez@telefonica.com',
    'david.alonsogarcia@telefonica.com',
    'nayra.romerosalas@telefonica.com',
    'evamaria.casaspoy@telefonica.com',
    'marta.martinezdevelasco@telefonica.com',
    'raquel.nodalsanchez@telefonica.com',
  ];
  const email = validationList.includes(userInfoSSFF?.username);
  const isAdmin = userInfoSSFF?.username === 'nayra.romerosalas@telefonica.com' || userInfoSSFF.username === 'pablo.navarrofh@telefonica.com';
  const telfisaAdmin = userInfoSSFF?.username === 'mariajose.navarromaillo@telefonica.com';
  const folders = [
    {
      id: 'termination',
      name: 'Baja voluntaria',
      icon: terminationIcon,
      description: 'Solicita tu baja voluntaria',
      path: '/form/termination',
    },
    {
      id: 'changeManager',
      name: 'Cambios Manager',
      icon: changeManagerIcon,
      description: 'Realiza cambios para uno o más empleados de tu equipo',
      path: '/form/changemanager',
      visible: isManager,
    },
    {
      id: 'changePeople',
      name: 'Cambios Area People',
      icon: changePeopleIcon,
      description: 'Gestiona cambios en el personal',
      path: '/form/listchangetickets',
      visible: isLifeCycleExperience || isPeopleExperience,
    },
    // {
    //   id: 'changeViewnext',
    //   name: 'Cambios Viewnext',
    //   icon: changeViewnextIcon,
    //   description: 'Gestiona cambios en el personal',
    //   path: '/form/changeviewnext',
    //   // path: '/form/tickets',
    // },
    {
      id: 'newEmployeeManager',
      name: 'Alta empleado (Manager)',
      icon: newEmpManagerIcon,
      description: 'Solicita el alta de un nuevo empleado',
      path: '/form/newEmployeeManager',
      visible: isManager,
    },
    // {
    //   id: 'newEmployeeChannel',
    //   name: 'Alta empleado (Canal Manager)',
    //   icon: peopleChangeIcon,
    //   description: 'Alta de nuevo empleado, área del Canal del Manager',
    //   path: '/form/newEmployeeChannel',
    // },
    // {
    //   id: 'newEmployeeRecruiter',
    //   name: 'Alta empleado (recruiter)',
    //   icon: peopleChangeIcon,
    //   description: 'Alta de nuevo empleado, área del recruiter',
    //   path: '/form/newEmployeeRecruiter',
    // },
    {
      id: 'contactUsTickets',
      name: 'Canal de atención LCExperience',
      icon: changePeopleIcon,
      description: 'Sección de gestión de tickets',
      path: '/form/ContactUsTickets',
      visible: isLifeCycleExperience || isPeopleExperience || email || telfisaAdmin,
    },
    {
      id: 'ContactUsEmployeeTickets',
      name: 'Canal de atención viewnext',
      icon: changePeopleIcon,
      description: 'Sección de gestión de tickets',
      path: '/form/ContactUsEmployeeTickets',
      visible: isViewnext || isAdmin,
    },
    {
      id: 'ticketsForm',
      name: 'Gestión de solicitudes',
      icon: manageRequestIcon,
      description: 'Formularios de baja, cambio y alta ',
      path: '/form/listTicketsForm',
      visible:
        isManager ||
        // isViewnext ||
        isLifeCycleExperience,
    },
  ];

  useEffect(() => {
    const fetchManagerPoblation = async () => {
      try {
        const response = await fetch(`/managerPoblation`, {
          method: 'GET',
          headers: {
            'x-msal-Authorization': `Bearer ${idToken}`,
          },
        });
        const managerEmployees = await response.json();
        setManagerEmployees(managerEmployees);
      } catch (error) {
        console.error('Error al obtener la población de Manager:', error.message);
      }
    };

    fetchManagerPoblation();
  }, [idToken, setManagerEmployees]);

  return (
    <section className='myFolderPage'>
      <div className='myFolderPage__content'>
        <h1 className='myFolderPage__title'>Formularios</h1>
        <div className='myFolderPage__options'>
          {folders
            .filter((folder) => folder.visible !== false)
            .map((folder) => (
              <div key={folder.id} className='myFolderPage__option'>
                <Link to={folder.path} className='myFolderPage__textContent'>
                  <div className='myFolderPage__imageContent'>
                    <img src={folder.icon} alt={folder.name} className='myFolderPage__image' />
                  </div>
                  <h1 className='myFolderPage__groupTitle'>{folder.name}</h1>
                  <p className='myFolderPage__description'>{folder.description}</p>
                  <div className='myFolderPage__arrowButton'>
                    <img src={arrowIcon} alt='arrow' className='myFolderPage__arrow' />
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <div className='myFolderPage__historyButton'>
        <Link to='/form/history' className='myFolderPage__historyLink'>
          <span className='myFolderPage__historyText'>Historial</span>
        </Link>
      </div>
    </section>
  );
};

export default MainPanelJira;
