// utils/ticketUtils.js

export const extractTicketData = (fields, type, attachments, ticketId, ticketKey) => {
  const extractField = (fieldKey) => {
    return fields[fieldKey] || '';
  };
  /*   const getFileExtension = (mimeType) => {
	  switch (mimeType) {
		case 'application/pdf':
		  return '.pdf';
		case 'text/html':
		  return '.html';
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		  return '.docx';
		// Añade más tipos según sea necesario
		default:
		  return ''; // O una extensión por defecto
	  }
	}; */

  const extractedAttachments = attachments.map((att) => ({
    filename: att.filename /* + getFileExtension(att.mimeType), */,
    content: att.content,
    id: att.id,
  }));

  if (type === 'change') {
    return {
      employeeName: extractField('customfield_10328'),
      managerName: extractField('customfield_10329'),
      managerEmail: extractField('customfield_10330'),
      effectiveDate: extractField('customfield_10331'),
      organizationalChange: {
        reorganization: extractField('customfield_10332'),
        newDepartments: extractField('customfield_10333'),
        responsiblePerson: extractField('customfield_10334'),
      },
      roleChange: {
        roleChangeDetails: extractField('customfield_10335'),
        managerChange: extractField('customfield_10336'),
        newManager: extractField('customfield_10337'),
      },
      salaryChange: {
        salary: extractField('customfield_10338'),
        currency: extractField('customfield_10339'),
        incentives: extractField('customfield_10340'),
        salaryReason: extractField('customfield_10341'),
      },
      locationChange: {
        city: extractField('customfield_10342'),
        country: extractField('customfield_10343'),
        building: extractField('customfield_10344'),
        floor: extractField('customfield_10345'),
      },
      contractChange: extractField('customfield_10346'),
      comments: extractField('comments'),
    };
  } else if (type === 'termination') {
    return {
      id: ticketId,
      key: ticketKey,
      employeeJuridic: extractField('customfield_10322'),
      employeeEmail: extractField('customfield_10319'),
      employeeName: extractField('customfield_10318'),
      employeeRegistrationId: extractField('customfield_10353'),
      employeeManagerEmail: extractField('customfield_10330'),
      terminationDate: extractField('customfield_10310'),
      terminationType: extractField('customfield_10312'),
      duration: extractField('customfield_10320'),
      transferToGroup: extractField('customfield_10317'),
      takenVacation: extractField('customfield_10316'),
      actionPlan: extractField('customfield_10363'),
      phoneLine: extractField('customfield_10321'),
      positionCovered: extractField('customfield_10356'),
      finiquitoAttached: extractField('customfield_10368'),
      finiquitoValidated: extractField('customfield_10361'),
      filesAttached: extractedAttachments,
    };
  } else {
    return {};
  }
};

const extractFieldValue = (field) => {
  // Si el campo tiene un valor directo, lo extraemos.
  if (field && field.value) {
    return field.value;
  }
  // Si el campo contiene contenido en formato de texto enriquecido, extraemos el texto.
  if (field && field.content && Array.isArray(field.content)) {
    const content = field.content[0];
    return content && content.type === 'paragraph' && content.content[0] && content.content[0].text ? content.content[0].text : '';
  }
  // Retornamos un string vacío si no hay valor o contenido.
  return '';
};
