import React, { useState } from 'react';
import '../styles/article-item.css';
import plus from '../../images/plus.svg';
import minus from '../../images/minus.svg';
import { Link } from 'react-router-dom';

const ArticleItem = ({ title, answer, expandedState, articleId }) => {
  // Estado para controlar si el artículo está expandido o no
  const [isExpanded, setIsExpanded] = useState(expandedState ? expandedState : false);

  // Función para alternar la expansión del artículo
  const handleToggle = async () => {
    setIsExpanded(!isExpanded);
  };
  const articleRoutes = {
    14: '/subcategories/articles/form/fusion',
    20: '/subcategories/articles/form/loan',
    17: '/subcategories/articles/form/healthinsurancebeneficiaries',
    235: '/subcategories/articles/form/healthinsurance',
    18: '/subcategories/articles/form/advance',
    28: ['/subcategories/articles/form/telematic', '/subcategories/articles/form/telematicperiods'],
    64: '/subcategories/articles/form/gourmetcard',
    60: '/subcategories/articles/form/lifeinsurancebeneficiaries',
    236: '/subcategories/articles/form/lifeinsurance',
  };

  return (
    <>
      <article className={`articleItem ${isExpanded ? 'expanded' : ''}`}>
        <div onClick={handleToggle} className={`articleItem__firstSection ${isExpanded ? 'expanded' : ''}`}>
          <p className='articleItem__title'>{title}</p>
          <div className='expandIconBox'>
            <img src={plus} alt='plus-symbol' className={`articleItem__plus ${isExpanded ? 'expanded' : ''}`} />
          </div>
          {isExpanded && <img src={minus} alt='minus-symbol' className={`articleItem__minus ${isExpanded ? 'expanded' : ''}`} />}
        </div>
        {isExpanded && (
          <>
            <div className='answerBox'>
              <p className='subcategories__articleList--article__description' dangerouslySetInnerHTML={{ __html: answer }}></p>
            </div>
          </>
        )}

        {isExpanded && articleRoutes[articleId] && (
          <div className='linkButtonContainer'>
            {Array.isArray(articleRoutes[articleId]) ? (
              articleRoutes[articleId].map((route, index) => (
                <Link to={route} key={index} className='linkButton'>
                  Ir al formulario {index + 1}
                </Link>
              ))
            ) : (
              <Link to={articleRoutes[articleId]} className='linkButton'>
                Ir al formulario
              </Link>
            )}
          </div>
        )}
      </article>
    </>
  );
};

export default ArticleItem;
