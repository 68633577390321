import React, { useState } from 'react';
import clipImage from '../../../images/clip-light.png';
import deleteFileIcon from '../../../images/PeoplExCancel.svg';
import { ALLOWED_TYPE_FILES } from '../../../utils/utils';
import { downloadAttachmentJira, updateJiraTicket, getJiraTicketAttachments } from '../../../services/apiJira';
import { extractTicketData } from '../historialTicketUtils';
import '../../styles/terminate/updateTickets.css';
import CryptoJS from 'crypto-js';
import DOMpurify from 'dompurify';
import { urlToBase64, readFileAsBase64, sanitize, base64ToBlob } from '../../../utils/utils';
import Popup from '../changeForm/formPeople/form/components/changeComponents/inputs/popUp';

const secretKey = 'your-secret-key';
const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};
const UpdateTickets = ({ userInfoSSFF, attachments, ticket, summary }) => {
  const [positionCovered, setPositionCovered] = useState(''); // Almacena si la posición será cubierta (Sí/No).
  const [terminationFile, setTerminationFile] = useState([]); // Almacena el archivo seleccionado para adjuntar.
  const [finiquitoAttached, setFiniquitoAttached] = useState(''); // Almacena si existe finiquito adjuntado.
  const [finiquitoValidated, setFiniquitoValidated] = useState(''); // Almacena la validación del finiquito (Sí/No).
  const [finiquitoAccepted, setFiniquitoAccepted] = useState(''); // Almacena la aceptación del finiquito (Sí/No).
  const [employeeComments, setEmployeeComments] = useState(''); // Almacena comentarios en caso de no aceptar el finiquito.
  const [files, setFiles] = useState([]);
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);
  const registrationId = userInfoSSFF?.empInfo.personNav.personalInfoNav.results[0].customString5;
  const [popupState, setPopupState] = useState({ isVisible: false, message: '' });

  const ticketData = extractTicketData(ticket.fields, 'termination', attachments, ticket.id, ticket.key, summary);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    const newFiles = [];
    for (let file of selectedFiles) {
      if (ALLOWED_TYPE_FILES.includes(file.name.split('.').pop())) {
        newFiles.push(file);
        setShowFormatNotSupported(false);
      } else {
        setShowFormatNotSupported(true);
      }
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    document.getElementById('files').value = null;
  };

  // Descarga el archivo adjuntado por el empleado en la creación de ticket.
  const handleEmpDocumentationClick = (fileId) => {
    if (fileId) {
      downloadAttachmentJira(fileId);
    } else {
      console.error('ID de archivo no válido para la descarga');
    }
  };

  const handleCloseFeedbackPopup = () => {
    setPopupState({ isVisible: false, message: '' });
  };

  // Condiciones para mostrar las secciones
  const isManager = userInfoSSFF?.custom15 === 'Y' || userInfoSSFF.manager?.email === ticketData.employeeManagerEmail;
  const isViewnext = userInfoSSFF?.division === 'VIEWNEXT';
  const isLifeCycleExperience = userInfoSSFF?.department === 'LIFECYCLE EXPERIENCE (DE107212)';
  const isEmployee = userInfoSSFF?.username === ticketData.employeeEmail;

  const handleUpdateTicket = async () => {
    try {
      // Función para encriptar y sanitizar valores
      const sanitize = (value) => DOMpurify.sanitize(value);

      // Campos a actualizar en el ticket
      const fields = {
        ...ticketData,
        positionCovered: encryptValue(sanitize(positionCovered.toString())),
        finiquitoAttached: encryptValue(sanitize(finiquitoAttached.toString())),
        finiquitoValidated: encryptValue(sanitize(finiquitoValidated.toString())),
        finiquitoAccepted: encryptValue(sanitize(finiquitoAccepted.toString())),
        employeeComments: encryptValue(sanitize(employeeComments.toString())),
        summary: summary,
      };

      // Definir campos personalizados de JIRA
      const customFields = {
        customfield_10356: fields.positionCovered,
        customfield_10368: fields.finiquitoAttached,
        customfield_10361: fields.finiquitoValidated,
        customfield_10362: fields.finiquitoAccepted,
        customfield_10370: fields.employeeComments,
        summary: fields.summary,
      };

      // Eliminar campos vacíos o no aplicables
      Object.keys(customFields).forEach((key) => {
        if (customFields[key] === '' || customFields[key] === 0) {
          delete customFields[key];
        }
      });

      // Combinar datos actualizados con los datos del ticket actual
      const updatedTicketData = { ...customFields };

      // Verifica que el archivo esté disponible antes de enviarlo
      if (!terminationFile) {
        alert('Por favor, adjunta un archivo antes de actualizar el ticket.');
        return;
      }

      let filesAttached = [];

      // Determinar si el usuario es de VIEWNEXT o si es el empleado
      const isViewnext = userInfoSSFF?.division === 'VIEWNEXT';
      // const isViewnext = userInfoSSFF?.department === 'LIFECYCLE EXPERIENCE (DE107212)';
      const isEmployee = userInfoSSFF?.username === ticketData.employeeEmail;

      // Si hay archivos adjuntos, procesar el último archivo subido
      if (files.length > 0) {
        // Recorremos todos los archivos para procesarlos
        const newFilesProcessed = await Promise.all(
          files.map(async (file, index) => {
            // Determina el número de archivo para asignar el nombre correspondiente.
            const nextFileNumber = filesAttached.length + index + 1; // Incrementa en 1 por cada archivo
            const paddedNumber = nextFileNumber.toString().padStart(2, '0'); // Asegura que el número tenga al menos dos dígitos

            // Extraemos la extensión del archivo original
            const fileExtension = file.name.split('.').pop(); // Obtiene la extensión después del último punto

            // Convertimos el archivo a base64
            const base64 = await readFileAsBase64(file);

            // Determina el nombre del archivo según las condiciones
            // if (isViewnext) {
            //   console.log('Usuario es VIEWNEXT');
            //   // Si el usuario es de VIEWNEXT
            //   return {
            //     name: `finiquito_${paddedNumber}_${ticketData.employeeRegistrationId}`,
            //     content: encryptValue(base64),
            //   };

            //TEMPORAL HASTA USUARIOS VIEWNEXT
            if (isLifeCycleExperience) {
              // Si el usuario es de VIEWNEXT
              return {
                name: `finiquito_${paddedNumber}_${ticketData.employeeRegistrationId}.${fileExtension}`, // Conserva la extensión
                content: encryptValue(base64),
              };
            } else if (isEmployee) {
              // Si el usuario es el empleado
              return {
                name: `finiquito-firmado_${paddedNumber}_${registrationId}.${fileExtension}`, // Conserva la extensión
                content: encryptValue(base64),
              };
            }
          })
        );
        // Unimos los nuevos archivos procesados con los anteriores (si ya había archivos)
        filesAttached = [...filesAttached, ...newFilesProcessed];
      }

      // Llamada a la función para actualizar el ticket, enviando los archivos solo si están disponibles
      const response = await updateJiraTicket(updatedTicketData, ticketData.id, filesAttached);
      if (response.status === 204) {
        setPopupState({
          isVisible: true,
          message: 'El ticket ha sido actualizado correctamente.',
        });
      } else {
        console.error('Error al actualizar el ticket: código de estado inesperado', response.status);
        setPopupState({
          isVisible: true,
          message: 'No se ha podido actualizar su ticket, compruebe sus datos.',
        });
      }
    } catch (error) {
      console.error('Error al actualizar el ticket', error);
    }
  };

  return (
    <section className='formJira_update'>
      <h1 className='formJira_update__title'>Solicitud de Baja voluntaria - Estado de la solicitud</h1>

      <table className='formJiraTable'>
        <tbody>
          <tr>
            <td className='formJiraTable__field'>Ticket ID:</td>
            <td className='formJiraTable__value'>{ticketData.id}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Ticket Key:</td>
            <td className='formJiraTable__value'>{ticketData.key}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Nombre del empleado:</td>
            <td className='formJiraTable__value'>{ticketData.employeeName}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Email:</td>
            <td className='formJiraTable__value'>{ticketData.employeeEmail}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Compañía:</td>
            <td className='formJiraTable__value'>{ticketData.employeeJuridic}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Matrícula:</td>
            <td className='formJiraTable__value'>{ticketData.employeeRegistrationId}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Email del manager:</td>
            <td className='formJiraTable__value'>{ticketData.employeeManagerEmail}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Fecha de la baja solicitada:</td>
            <td className='formJiraTable__value'>{ticketData.terminationDate}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Tipo de baja solicitada:</td>
            <td className='formJiraTable__value'>{ticketData.terminationType}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Duración de baja solicitada:</td>
            <td className='formJiraTable__value'>{ticketData.duration}</td>
          </tr>
          {(isLifeCycleExperience || isViewnext || isEmployee) && (
            <tr>
              <td className='formJiraTable__field'>Documentación del empleado:</td>
              <td className='formJiraTable__value'>
                {ticketData?.filesAttached?.filter((file) => file.filename.startsWith('documentacion_')).length > 0 ? (
                  ticketData.filesAttached
                    .filter((file) => file.filename.startsWith('documentacion_'))
                    .map((file) => (
                      <div key={file.id} className='formJiraTable__file'>
                        <label className='formJiraTable__value pointer' onClick={() => handleEmpDocumentationClick(file.id)}>
                          {file.filename}
                        </label>
                      </div>
                    ))
                ) : (
                  <p>Sin archivo adjunto</p>
                )}
              </td>
            </tr>
          )}
          <tr>
            <td className='formJiraTable__field'>Traspaso a otra compañía del grupo:</td>
            <td className='formJiraTable__value'>{ticketData.transferToGroup}</td>
          </tr>
          <tr>
            <td className='formJiraTable__field'>Número de días de vacaciones disfrutados:</td>
            <td className='formJiraTable__value'>{ticketData.takenVacation}</td>
          </tr>
          {(isLifeCycleExperience || isViewnext || isEmployee) && (
            <tr>
              <td className='formJiraTable__field'>Plan de acciones:</td>
              <td className='formJiraTable__value'>{ticketData.actionPlan}</td>
            </tr>
          )}
          {(isLifeCycleExperience || isViewnext || isEmployee) && (
            <tr>
              <td className='formJiraTable__field'>Número de teléfono:</td>
              <td className='formJiraTable__value'>{ticketData.phoneLine}</td>
            </tr>
          )}
          {(isLifeCycleExperience || isViewnext || isManager) && (
            <tr>
              <td className='formJiraTable__field'>Posición amortizada por Manager:</td>
              <td className='formJiraTable__value'>
                <div>{ticketData?.positionCovered}</div>
              </td>
            </tr>
          )}
          {(isLifeCycleExperience || isViewnext || isEmployee) && (
            <tr>
              <td className='formJiraTable__field'>Finiquito adjuntado por Viewnext:</td>
              <td className='formJiraTable__value'>
                {ticketData?.filesAttached?.filter((file) => file.filename.startsWith('finiquito_')).length > 0 ? (
                  ticketData.filesAttached
                    .filter((file) => file.filename.startsWith('finiquito_'))
                    .map((file) => (
                      <div key={file.id} className='formJiraTable__file'>
                        <label className='formJiraTable__value pointer' onClick={() => handleEmpDocumentationClick(file.id)}>
                          {file.filename}
                        </label>
                      </div>
                    ))
                ) : (
                  <p>Sin archivo adjunto</p>
                )}
              </td>
            </tr>
          )}
          {(isLifeCycleExperience || isViewnext) && (
            <tr>
              <td className='formJiraTable__field'>Validación de Finiquito por Life Cycle Exp:</td>
              <td className='formJiraTable__value'>
                <div>{ticketData?.finiquitoValidated}</div>
              </td>
            </tr>
          )}
          {(isLifeCycleExperience || isViewnext || isEmployee) && (
            <tr>
              <td className='formJiraTable__field'>Finiquito firmado por empleado:</td>
              <td className='formJiraTable__value'>
                {ticketData?.filesAttached?.filter((file) => file.filename.startsWith('finiquito-firmado_')).length > 0 ? (
                  ticketData.filesAttached
                    .filter((file) => file.filename.startsWith('finiquito-firmado_'))
                    .map((file) => (
                      <div key={file.id} className='formJiraTable__file'>
                        <label className='formJiraTable__value pointer' onClick={() => handleEmpDocumentationClick(file.id)}>
                          {file.filename}
                        </label>
                      </div>
                    ))
                ) : (
                  <p>Sin archivo adjunto</p>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className='formJiraUpdate__section'>
        <h2 className='formJira_update__subtitle'>Acciones</h2>
        {isManager && (
          <div className='formJiraUpdate__section_manager'>
            <label className='formJiraUpdate__section__actionTitle'>Manager: ¿Crees que la posición se va a amortizar?</label>
            <label className='formJiraUpdate__section__radio'>
              <input
                type='radio'
                name='positionCovered'
                value='Sí'
                checked={positionCovered === 'Sí'}
                onChange={() => {
                  setPositionCovered('Sí');
                }}
                className='formJiraUpdate__radio'
              />
              Sí
            </label>
            <label className='formJiraUpdate__section__radio'>
              <input
                type='radio'
                name='positionCovered'
                value='No'
                checked={positionCovered === 'No'}
                onChange={() => {
                  setPositionCovered('No');
                }}
                className='formJiraUpdate__radio'
              />
              No
            </label>
          </div>
        )}

        {/* {isViewnext && ( */}
        {/* //TEMPORAL HASTA USUARIOS VIEWNEXT */}
        {isLifeCycleExperience && (
          <div className='formJiraUpdate__section_viewnext'>
            <label className='formJiraUpdate__section__actionTitle'>Viewnext: Adjuntar finiquito</label>
            <label className='formJiraUpdate__uploadFiles'>
              <input onChange={handleFileChange} type='file' className='formJiraUpdate__uploadFiles--input' id='files' />
              <label htmlFor='files'>
                <img src={clipImage} className='formJiraUpdate--clip' alt='Adjuntar archivos' />
              </label>
            </label>

            {showFormatNotSupported && (
              <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
            )}

            {files.length !== 0 && (
              <div className='assistancePopup__fileSection'>
                {files.map((file, index) => (
                  <div key={index} className='assistancePopup__fileSection__fileSelected'>
                    <span className='assistancePopup__fileSection--fileName'>{file.name}</span>
                    <img
                      className='assistancePopup__fileSection--deleteFileIcon'
                      src={deleteFileIcon}
                      alt='deleteFile'
                      onClick={() => removeFile(index)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {isLifeCycleExperience && (
          <div className='formJiraUpdate__section_lce'>
            <label className='formJiraUpdate__section__actionTitle'>Life Cycle Experience: Validar finiquito</label>
            <label className='formJiraUpdate__section__radio'>
              <input
                type='radio'
                name='finiquitoValidated'
                value='Sí'
                checked={finiquitoValidated === 'Sí'}
                onChange={() => setFiniquitoValidated('Sí')}
                className='formJiraUpdate__radio'
              />
              Sí
            </label>
            <label className='formJiraUpdate__section__radio'>
              <input
                type='radio'
                name='finiquitoValidated'
                value='No'
                checked={finiquitoValidated === 'No'}
                onChange={() => setFiniquitoValidated('No')}
                className='formJiraUpdate__radio'
              />
              No
            </label>
          </div>
        )}
      </div>
      {ticketData.finiquitoValidated === 'Sí' && isEmployee && (
        <div className='formJiraUpdate__section_lce'>
          <label className='formJiraUpdate__section__actionTitle'>{ticketData.employeeName}, ¿estás de acuerdo con el finiquito?</label>
          <label className='formJiraUpdate__section__radio'>
            <input
              type='radio'
              name='finiquitoAccepted'
              value='Sí'
              checked={finiquitoAccepted === 'Sí'}
              onChange={() => setFiniquitoAccepted('Sí')}
              className='formJiraUpdate__radio'
            />
            Sí
          </label>
          <label className='formJiraUpdate__section__radio'>
            <input
              type='radio'
              name='finiquitoAccepted'
              value='No'
              checked={finiquitoAccepted === 'No'}
              onChange={() => setFiniquitoAccepted('No')}
              className='formJiraUpdate__radio'
            />
            No
          </label>
          {/* Mostrar si el usuario selecciona "Sí" */}
          {finiquitoAccepted === 'Sí' && (
            <div className='formJiraUpdate__uploadFiles--file'>
              <p>Gracias, no olvides adjuntar el finiquito firmado aquí:</p>
              <label className='formJiraUpdate__uploadFiles'>
                <input onChange={handleFileChange} type='file' className='formJiraUpdate__uploadFiles--input' id='files' />
                <label htmlFor='files'>
                  <img src={clipImage} className='formJiraUpdate--clip' alt='Adjuntar archivos' />
                </label>
              </label>

              {/* Mostrar advertencia si el formato del archivo no es soportado */}
              {showFormatNotSupported && (
                <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
              )}

              {/* Mostrar lista de archivos seleccionados */}
              {files.length !== 0 && (
                <div className='assistancePopup__fileSection'>
                  {files.map((file, index) => (
                    <div key={index} className='assistancePopup__fileSection__fileSelected'>
                      <span className='assistancePopup__fileSection--fileName'>{file.name}</span>
                      <img
                        className='assistancePopup__fileSection--deleteFileIcon'
                        src={deleteFileIcon}
                        alt='deleteFile'
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {/* Mostrar si el usuario selecciona "No" */}
          {finiquitoAccepted === 'No' && (
            <div>
              <p>Escribe aquí tus razones y contactaremos contigo lo antes posible:</p>
              <textarea
                className='formJiraUpdate__textarea'
                placeholder='Escribe tu comentario...'
                value={employeeComments}
                onChange={(e) => setEmployeeComments(e.target.value)}
              />
            </div>
          )}
        </div>
      )}

      <div className='formJiraUpdateButtonContainer'>
        <button
          className='formJiraUpdateButton'
          onClick={() => {
            handleUpdateTicket();
          }}
        >
          Actualizar ticket
        </button>
      </div>
      {popupState.isVisible && (
        <>
          {console.log('Renderizando Popup con el mensaje:', popupState.message)}
          <Popup type='success' message={popupState.message} onClose={handleCloseFeedbackPopup} />
        </>
      )}
    </section>
  );
};

export default UpdateTickets;
