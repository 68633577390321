import React, { useState, useEffect } from 'react';
//styles
import '../styles/search_article-list.css';

//images
import elipse355 from '../../images/ellipse355.png';

//components
import ArticleItem from './article-item';
import { Link } from 'react-router-dom';

import { fetchSearchArticles } from '../../services/api';

const SearchArticleList = ({ filterInSearcher, userInfoSSFF }) => {
  const [dataArticlesSearcher, setDataArticlesSearcher] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //Articles & Roles
  useEffect(() => {
    const fetchSearchArticlesComponent = async () => {
      try {
        setIsLoading(true);
        let subcategoriesArticlesData = await fetchSearchArticles(userInfoSSFF.custom03, filterInSearcher);
        setDataArticlesSearcher(subcategoriesArticlesData || []);
        setIsLoading(false);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchSearchArticlesComponent();
  }, [filterInSearcher]);

  return (
    <>
      <section className='search__articleList'>
        <div className='articleList__breadcrumb--container'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/knowledgepage' className='articleList__breadcrumb--title'>
            INICIO {''}
          </Link>
          <span className='articleList__breadcrumb--text'>&gt; RESULTADOS DE TU BÚSQUEDA</span>
        </div>
        <h4 className='search__ArticleList--title'>Resultados de tu búsqueda</h4>
        {/* <p className='search__ArticleList--search'>{dataArticlesSearcher.length !== 0 ? filterInSearcher : null}</p> */}
        {/* Mostrar un mensaje de carga mientras se espera la respuesta */}
        {isLoading ? (
          <p>Cargando resultados...</p>
        ) : (
          // Listado artículos
          <section className='search__articleList__content'>
            {dataArticlesSearcher.length ? (
              dataArticlesSearcher.map((article) => (
                <ArticleItem
                  key={article.article_id}
                  title={article.title}
                  answer={article.content}
                  expandedState={false}
                  // clickCode={article.tracking.clickCode}
                  // rateCode={article.tracking.rateCode}
                  userInfoSSFF={userInfoSSFF}
                  articleId={article.article_id}
                  // contactMe={article.attributes.CONTACT_MANAGER}
                ></ArticleItem>
              ))
            ) : (
              <p>
                No hay resultados. Visita las{' '}
                <Link to='/home#bubbles-categories' className='categorias__noResult'>
                  categorías
                </Link>{' '}
                de nuestra página de inicio.
              </p>
            )}
          </section>
        )}
      </section>
    </>
  );
};

export default SearchArticleList;
