import React, { useEffect, useState } from 'react';
import { validateFields } from '../validateFields';

export const SalaryChangePeople = ({ formData, handleInputChange, errors = {}, setErrors, initialValues = {} }) => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    // Validar campos al iniciar y al cambiar
    const fields = {
      salaryChange: formData.salaryChange,
      currency: formData.currency,
      bonusOrIncentives: formData.bonusOrIncentives,
      incentivesAmount: formData.incentivesAmount,
      salaryReason: formData.salaryReason,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [formData, setErrors]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldName === 'salaryReason' || fieldName === 'currency' || fieldName === 'salaryChange') {
      return fieldValue && fieldValue === initialValues[fieldName]
        ? 'formJiraChangeInputText prefilledBorder'
        : errors[fieldName]
        ? 'formJiraChangeInputText errorBorder'
        : 'formJiraChangeInputText';
    }

    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText_short prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeInputText_short errorBorder' : 'formJiraChangeInputText_short';
  };

  const getRadioClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios salariales</div>

      {/* Campo: Nuevo salario fijo anual */}
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          Nuevo salario fijo anual:
          <input
            type='number'
            className={getInputClass(formData.salaryChange, 'salaryChange')}
            value={formData.salaryChange || ''}
            onChange={(e) => handleInputChange('salaryChange', e.target.value)}
            placeholder='Salario anual...'
            min={0}
          />
        </label>

        {/* Campo: Moneda */}
        <label className='formJiraChangeLabel_bothIndividual'>
          Moneda:
          <select
            className={getInputClass(formData.currency, 'currency')}
            value={formData.currency || ''}
            onChange={(e) => handleInputChange('currency', e.target.value)}
          >
            <option value='' disabled>
              Seleccione
            </option>
            <option value='EUR'>EUR - Euro</option>
          </select>
        </label>
      </div>

      {/* Campo: Bonus o Incentivos Comerciales */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short'>Bonus o Incentivos Comerciales:</label>
        <div className='formJiraChangeRadioGroup'>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='bonusOrIncentives'
              className={getRadioClass(formData.bonusOrIncentives, 'bonusOrIncentives')}
              value='Bonus'
              checked={formData.bonusOrIncentives === 'Bonus'}
              onChange={() =>
                handleInputChange('bonusOrIncentives', {
                  ...formData.bonusOrIncentives,
                  value: 'Bonus',
                })
              }
            />
            Bonus
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='bonusOrIncentives'
              className={getRadioClass(formData.bonusOrIncentives, 'bonusOrIncentives')}
              value='Incentivos comerciales'
              checked={formData.bonusOrIncentives === 'Incentivos comerciales'}
              onChange={() =>
                handleInputChange('bonusOrIncentives', {
                  ...formData.bonusOrIncentives,
                  value: 'Incentivos comerciales',
                })
              }
            />
            Incentivos Comerciales
          </label>
        </div>

        {/* Campo: Importe */}
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel_short'>Importe:</label>
          <input
            type='number'
            className={getInputClass(formData.incentivesAmount, 'incentivesAmount')}
            value={formData.incentivesAmount || ''}
            onChange={(e) => handleInputChange('incentivesAmount', e.target.value)}
            placeholder='Importe...'
            min={0}
          />
        </div>
      </div>

      {/* Campo: Motivo del cambio */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>¿Cuál es el motivo del cambio?</label>
        <input
          type='text'
          className={getInputClass(formData.salaryReason, 'salaryReason')}
          value={formData.salaryReason}
          onChange={(e) => handleInputChange('salaryReason', e.target.value)}
          placeholder='Motivo del cambio...'
        />
      </div>
    </div>
  );
};
