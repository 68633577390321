import React, { useEffect, useState } from 'react';
import { LabelInput, LabelInputBoth } from '../inputs/labelInput';
import { validateFields } from '../validateFields';

const NewJobClassification = ({ formData, handleInputChange, showForm }) => {
  const [errors, setErrors] = useState({});

  const legalEntities = [
    'Telefónica Compras Electrónicas',
    'Telefónica Global Solutions',
    'Telefónica Hispanoamérica',
    'Telefónica Innovación Digital',
    'Telefónica Open Innovation',
    'Telefónica S.A.',
    'Telefónica Soluciones de Criptografía',
    'Telxius Cable España',
    'Telxius Telecom',
    'CS2',
  ];

  useEffect(() => {
    const fields = {
      jcTitle: formData.jcTitle,
      jcLevel: formData.jcLevel,
      jcPayGrade: formData.jcPayGrade,
      jcTelefonicaLevel: formData.jcTelefonicaLevel,
      jcTelefonicaGrade: formData.jcTelefonicaGrade,
      legalEntity: formData.legalEntity,
      jobFunction: formData.jobFunction,
      jcCreationDate: formData.jcCreationDate,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
  }, [formData]);

  if (!showForm) return null;

  return (
    <>
      <LabelInput
        label='Título del puesto:'
        value={formData.jcTitle || ''}
        onChange={(e) => handleInputChange('jcTitle', e.target.value)}
        error={errors.jcTitle}
      />
      <div className='formJiraChangeSection_both'>
        <div className='formInputGroup'>
          <label className='formJiraChangeLabel_Manager'>Nivel de puesto:</label>
          <select
            className={`formInput ${errors.jcLevel ? 'inputError' : ''}`}
            value={formData.jcLevel || ''}
            onChange={(e) => handleInputChange('jcLevel', e.target.value)}
          >
            <option value=''>Seleccione una opción</option>
            <option value='IC'>IC</option>
            <option value='MM'>MM</option>
            <option value='VAL'>VAL</option>
          </select>
          {errors.jcLevel && <span className='errorText'>{errors.jcLevel}</span>}
        </div>
        <LabelInputBoth
          label='Grado de Pago:'
          value={formData.jcPayGrade || ''}
          onChange={(e) => handleInputChange('jcPayGrade', e.target.value)}
          error={errors.jcPayGrade}
        />
      </div>
      <div className='formJiraChangeSection_both'>
        <LabelInputBoth
          label='Nivel Telefónica:'
          value={formData.jcTelefonicaLevel || ''}
          onChange={(e) => handleInputChange('jcTelefonicaLevel', e.target.value)}
          error={errors.jcTelefonicaLevel}
        />
        <LabelInputBoth
          label='Grado Telefónica:'
          value={formData.jcTelefonicaGrade || ''}
          onChange={(e) => handleInputChange('jcTelefonicaGrade', e.target.value)}
          error={errors.jcTelefonicaGrade}
        />
      </div>
      <div className='formJiraChangeSection_both'>
        <div className='formInputGroup'>
          <label className='formJiraChangeLabel_Manager'>Entidad Jurídica para Job Classification:</label>
          <select
            className={`formInput ${errors.legalEntity ? 'inputError' : ''}`}
            value={formData.legalEntity || ''}
            onChange={(e) => handleInputChange('legalEntity', e.target.value)}
          >
            <option value=''>Seleccione una opción</option>
            {legalEntities.map((entity, index) => (
              <option key={index} value={entity}>
                {entity}
              </option>
            ))}
          </select>
          {errors.legalEntity && <span className='errorText'>{errors.legalEntity}</span>}
        </div>
        <LabelInputBoth
          label='Fecha de la creación de la JC:'
          type='date'
          value={formData.jcCreationDate || ''}
          onChange={(e) => handleInputChange('jcCreationDate', e.target.value)}
          error={errors.jcCreationDate}
        />
      </div>
      <LabelInput
        label='Función de puesto:'
        value={formData.jobFunction || ''}
        onChange={(e) => handleInputChange('jobFunction', e.target.value)}
        error={errors.jobFunction}
      />
    </>
  );
};

export default NewJobClassification;
