import React from 'react';

export const OthersChangePeople = ({ formData, handleInputChange }) => {
  const picklistOptions = ['Completado', 'En curso'];

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      {/* Cambios organizativos */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='organizationalChangeStatus'>
          Cambios organizativos, rol o categoría
        </label>
        <select
          id='organizationalChangeStatus'
          className='formJiraChangeInputText'
          value={formData.organizationalChangeStatus || ''}
          onChange={(e) => handleInputChange('organizationalChangeStatus', e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Cambios salariales */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='salaryChangeStatus'>
          Cambios salariales
        </label>
        <select
          id='salaryChangeStatus'
          className='formJiraChangeInputText'
          value={formData.salaryChangeStatus || ''}
          onChange={(e) => handleInputChange('salaryChangeStatus', e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Cambios de localización */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='locationChangeStatus'>
          Cambios de localización
        </label>
        <select
          id='locationChangeStatus'
          className='formJiraChangeInputText'
          value={formData.locationChangeStatus || ''}
          onChange={(e) => handleInputChange('locationChangeStatus', e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Cambios de contrato */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='contractChangeStatus'>
          Cambios de contrato
        </label>
        <select
          id='contractChangeStatus'
          className='formJiraChangeInputText'
          value={formData.contractChangeStatus || ''}
          onChange={(e) => handleInputChange('contractChangeStatus', e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Solicitud de cambio completada */}
      <div className='formJiraChangeLabelRadio'>
        <label className='formJiraChangeLabel'>Solicitud de cambio completada</label>
        <div className='formJiraChangeRadio'>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='changeRequestCompleted'
              value='Sí'
              checked={formData.changeRequestCompleted === 'Sí'}
              onChange={() => handleInputChange('changeRequestCompleted', 'Sí')}
            />
            Sí
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='changeRequestCompleted'
              value='No'
              checked={formData.changeRequestCompleted === 'No'}
              onChange={() => handleInputChange('changeRequestCompleted', 'No')}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};
