import React, { useEffect, useState } from 'react';
import elipse242 from '../../../../../../images/ellipse242.png';
import { validateFields } from '../formValidate';

export const ContractChange = ({ contractChange, setContractChange }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState('');

  useEffect(() => {
    setInitialValues({
      contractChange,
    });
  }, []);

  useEffect(() => {
    const fields = {
      contractChange,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [contractChange]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  return (
    <div className='formJiraCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios de contrato</div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.contractChange && '*'} Tipo de Contrato:
          <select
            className={getInputClass(contractChange, 'contractChange')}
            value={contractChange}
            onChange={(e) => setContractChange(e.target.value)}
          >
            <option value='' disabled>
              Seleccione un tipo de contrato...
            </option>
            <option value='Indefinido'>Indefinido</option>
          </select>
        </label>
      </div>
    </div>
  );
};
