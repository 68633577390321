import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../../../styles/change/formChangeManager.css';
import elipse355 from '../../../../../../images/ellipse355.png';
import { Link } from 'react-router-dom';
import ContactInfoWithPopup from './changeComponents/contactInfoWithPopUp';
import GeneralInfoPeople from './changeComponents/formComponents/generalInfoPeople';
import { OrganizationalChangePeople } from './changeComponents/formComponents/organizationalChangePeople';
import { SalaryChangePeople } from './changeComponents/formComponents/salaryChangePeople';
import { LocationChangePeople } from './changeComponents/formComponents/locationChangePeople';
import { ContractChangePeople } from './changeComponents/formComponents/contractChangePeople';
import { OthersChangePeople } from './changeComponents/formComponents/othersChangePeople';
import CommentsForm from './changeComponents/commentsForm';
import handleSubmit from './changeComponents/handleSubmit';
import Popup from './changeComponents/inputs/popUp';
import { validateFields } from './changeComponents/validateFields';

const FormPeopleMain = ({ userInfoSSFF }) => {
  const location = useLocation(); // Obtén el estado pasado
  const ticketData = location.state?.ticket?.fields || {}; // Extrae los datos del ticket
  const ticketId = location.state?.ticket?.id || {}; // Extrae el id del ticket

  // Mapeo entre campos del formulario y customfields
  const fieldMapping = {
    // Información General
    managerName: 'customfield_10374',
    managerEmail: 'customfield_10375',
    employeeName: 'customfield_10376',
    employeeEmail: 'customfield_10377',
    employeeSSFFId: 'customfield_10378',
    employeeRegistrationId: 'customfield_10379',
    employeeLegalEntity: 'customfield_10380',
    effectiveDate: 'customfield_10381',
    // Cambios Organizativos
    organizationalChange: 'customfield_10383',
    newDepartments: 'customfield_10384',
    newManager: 'customfield_10385',
    newAsigneeManager: 'customfield_10386',
    roleChange: 'customfield_10387',
    roleChangeDetails: 'customfield_10391',
    newDuties: 'customfield_10389',
    newDutiesDetails: 'customfield_10390',
    categoryChange: 'customfield_10392',
    categoryChangeDetails: 'customfield_10393',
    hrbp: 'customfield_10394',
    businessUnit: 'customfield_10395',
    division: 'customfield_10396',
    department: 'customfield_10349',
    costCenter: 'customfield_10397',
    objectivesResult: 'customfield_10398',
    newJobClasification: 'customfield_10399',
    jcTitle: 'customfield_10400',
    jcLevel: 'customfield_10401',
    jcPayGrade: 'customfield_10402',
    jcTelefonicaLevel: 'customfield_10405',
    jcTelefonicaGrade: 'customfield_10403',
    legalEntity: 'customfield_10406',
    jobFunction: 'customfield_10407',
    jcCreationDate: 'customfield_10408',
    jcCreated: 'customfield_10409',
    jobClassification: 'customfield_10410',
    globalRole: 'customfield_10411',
    category: 'customfield_10412',
    benchmarkCode: 'customfield_10415',
    newJobPosition: 'customfield_10416',
    newPositionCreated: 'customfield_10420',
    employeeAssignedToNewPosition: 'customfield_10421',
    positionCode: 'customfield_10422',
    positionChangeRequired: 'customfield_10462',
    positionChangeComments: 'customfield_10463',
    // Cambios Salariales
    salaryChange: 'customfield_10423',
    currency: 'customfield_10424',
    bonusOrIncentives: 'customfield_10425',
    incentivesAmount: 'customfield_10426',
    salaryReason: 'customfield_10427',
    // Cambios de Localización
    city: 'customfield_10428',
    country: 'customfield_10343',
    building: 'customfield_10344',
    floor: 'customfield_10429',
    // Cambios de contrato
    contractChange: 'customfield_10430',
    hours: 'customfield_10431',
    // Observaciones
    comments: 'customfield_10436',
    // Otros
    organizationalChangeStatus: 'customfield_10432',
    salaryChangeStatus: 'customfield_10433',
    locationChangeStatus: 'customfield_10434',
    contractChangeStatus: 'customfield_10435',
    changeRequestCompleted: 'customfield_10440',
  };

  // Estado de formData inicializado con valores de ticketData
  const [formData, setFormData] = useState(
    Object.keys(fieldMapping).reduce((acc, key) => {
      acc[key] = ticketData[fieldMapping[key]] || (key === 'changeRequestCompleted' ? 'No' : '');
      return acc;
    }, {})
  );

  const [changeType, setChangeType] = useState(['info']);
  const [focusedButton, setFocusedButton] = useState('info');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupState, setPopupState] = useState({ isVisible: false, message: '' });
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);

  const updatePopupState = useCallback((newState) => {
    setPopupState(newState);
  }, []);

  function handleInputChange(keyToUpdate, newValue) {
    setFormData((prevFormData) => {
      // Crear una copia del estado actual
      const updatedFormData = { ...prevFormData };

      // Verificar si la clave existe en formData
      if (updatedFormData.hasOwnProperty(keyToUpdate)) {
        // Caso: el valor es un objeto con una clave "value"
        if (
          typeof updatedFormData[keyToUpdate] === 'object' &&
          updatedFormData[keyToUpdate] !== null &&
          updatedFormData[keyToUpdate].hasOwnProperty('value')
        ) {
          updatedFormData[keyToUpdate].value = newValue; // Asignar el valor directamente
        } else {
          // Caso: el valor es directo (no un objeto con "value")
          updatedFormData[keyToUpdate] = newValue;
        }
      } else {
        console.warn(`La clave ${keyToUpdate} no existe en el objeto.`);
      }

      // Retornar el nuevo estado actualizado
      return updatedFormData;
    });
  }

  //Campos del formulario
  ////General Info
  const [employeeName, setEmployeeName] = useState(ticketData?.employeeName || '');
  const [employeeEmail, setEmployeeEmail] = useState(ticketData?.employeeEmail || '');
  const [employeeSSFFId, setEmployeeSSFFId] = useState(ticketData?.employeeSSFFId || '');
  const [effectiveDate, setEffectiveDate] = useState(ticketData?.effectiveDate || '');
  const [managerName, setManagerName] = useState(ticketData?.managerName || '');
  const [managerEmail, setManagerEmail] = useState(ticketData?.managerEmail || '');
  ////Organizational Change
  const [organizationalChange, setOrganizationalChange] = useState(ticketData?.organizationalChange?.organizationalChange.value || false);
  const [newDepartments, setNewDepartments] = useState(ticketData?.organizationalChange?.newDepartments || '');
  const [newManager, setNewManager] = useState(ticketData?.organizationalChange?.newManager || '');
  const [newAsigneeManager, setNewAsigneeManager] = useState(ticketData?.organizationalChange?.newAsigneeManager || '');
  const [roleChange, setRoleChange] = useState(ticketData?.organizationalChange?.roleChange || '');
  const [roleChangeDetails, setRoleChangeDetails] = useState(ticketData?.organizationalChange?.roleChangeDetails || '');
  const [newDuties, setNewDuties] = useState(ticketData?.organizationalChange?.newDuties || '');
  const [newDutiesDetails, setNewDutiesDetails] = useState(ticketData?.organizationalChange?.newDutiesDetails || '');
  const [categoryChange, setCategoryChange] = useState(ticketData?.organizationalChange?.categoryChange || '');
  const [categoryChangeDetails, setCategoryChangeDetails] = useState(ticketData?.organizationalChange?.categoryChangeDetails || '');
  const [hrbp, setHrbp] = useState(ticketData?.organizationalChange?.hrbp || '');
  const [businessUnit, setBusinessUnit] = useState('');
  const [division, setDivision] = useState('');
  const [department, setDepartment] = useState('');
  const [costCenter, setCostCenter] = useState('');
  const [objectivesResult, setObjectivesResult] = useState('');
  ////Job Classification
  const [isNewJobClasification, setIsNewJobClasification] = useState(false);
  const [jcTitle, setJcTitle] = useState('');
  const [jcLevel, setJcLevel] = useState('');
  const [jcPayGrade, setJcPayGrade] = useState('');
  const [jcTelefonicaLevel, setjcTelefonicaLevel] = useState('');
  const [jcTelefonicaGrade, setjcTelefonicaGrade] = useState('');
  const [legalEntity, setLegalEntity] = useState('');
  const [jobFunction, setJobFunction] = useState('');
  const [jcCreationDate, setJcCreationDate] = useState('');
  const [jcCreated, setJcCreated] = useState('');
  const [jobClassification, setJobClassification] = useState('');
  const [globalRole, setGlobalRole] = useState('');
  const [category, setCategory] = useState('');
  const [benchmarkCode, setBenchmarkCode] = useState('');
  ////Job Position
  const [isNewJobPosition, setIsNewJobPosition] = useState(false);
  const [positionTitle, setPositionTitle] = useState('');
  const [positionStartDate, setPositionStartDate] = useState('');
  const [positionCompany, setPositionCompany] = useState('');
  const [newPositionCreated, setNewPositionCreated] = useState('');
  const [employeeAssignedToNewPosition, setEmployeeAssignedToNewPosition] = useState('');
  const [positionChangeRequired, setPositionChangeRequired] = useState('');
  const [positionCode, setPositionCode] = useState('');
  const [positionChangeComments, setPositionChangeComments] = useState('');
  ////Salary Change
  const [salaryChange, setSalaryChange] = useState(ticketData?.salaryChange?.salary || '');
  const [currency, setCurrency] = useState(ticketData?.salaryChange?.currency || '');
  const [bonusOrIncentives, setBonusOrIncentives] = useState('');
  const [bonusPercentage, setBonusPercentage] = useState('');
  const [incentivesAmount, setIncentivesAmount] = useState(ticketData?.salaryChange?.incentives || '');
  const [salaryReason, setSalaryReason] = useState(ticketData?.salaryChange?.salaryReason || '');
  ////Location Change
  const [locationChange, setLocationChange] = useState(ticketData?.locationChange?.locationChange || false);
  const [city, setCity] = useState(ticketData?.locationChange?.city || '');
  const [country, setCountry] = useState(ticketData?.locationChange?.country || '');
  const [building, setBuilding] = useState(ticketData?.locationChange?.building || '');
  const [floor, setFloor] = useState(ticketData?.locationChange?.floor || '');
  const [changeDetails, setChangeDetails] = useState('');
  const [errors, setErrors] = useState({});
  //// Contract Change
  const [contractChange, setContractChange] = useState(ticketData?.contractChange || '');
  const [hours, setHours] = useState('');
  //// Observaciones
  const [comments, setComments] = useState('');
  //// Others
  const [organizationalChangeStatus, setOrganizationalChangeStatus] = useState('');
  const [salaryChangeStatus, setSalaryChangeStatus] = useState('');
  const [locationChangeStatus, setLocationChangeStatus] = useState('');
  const [contractChangeStatus, setContractChangeStatus] = useState('');
  const [changeRequestCompleted, setChangeRequestCompleted] = useState('');

  useEffect(() => {
    setChangeType(['info']);
    setFocusedButton('info');
  }, []);

  const handleMenuClick = (type) => {
    if (typeof type !== 'string') {
      console.error('El tipo de cambio debe ser una cadena:', type);
      return;
    }
    setChangeType([type]);
    setFocusedButton(type);
  };

  useEffect(() => {
    const isFormValid = validateFields(formData); // Valida el formulario
    setIsButtonEnabled(isFormValid); // Actualiza el estado
  }, [formData]);

  const handleClearForm = () => {
    setFormData(
      Object.keys(fieldMapping).reduce((acc, key) => {
        acc[key] = ticketData[fieldMapping[key]] || '';
        return acc;
      }, {})
    );
    setChangeType(['info']);
    setFocusedButton('info');
    setIsButtonEnabled(false);
  };

  const handleCloseFeedbackPopup = () => {
    setPopupState({ isVisible: false, message: '' });
    setMessageSendingFailed(false);
  };

  const menuItems = [
    { type: 'info', label: 'Información General' },
    { type: 'Organizational', label: 'Cambios Organizativos' },
    { type: 'Salary', label: 'Cambios Salariales' },
    { type: 'Location', label: 'Cambios de Localización' },
    { type: 'Contract', label: 'Cambios de Contrato' },
    { type: 'comments', label: 'Observaciones' },
    { type: 'others', label: 'Otros' },
  ];

  return (
    <section className='formJiraChange'>
      <div className='jiraChangeForm__breadcrumb'>
        <img src={elipse355} alt='circle-icon' className='circle-icon' />
        <Link to='/form' className='jiraChangeForm__breadcrumb--title'>
          INICIO
        </Link>
        <div className='jiraChangeForm__breadcrumb--text'>&gt; SOLICITUD DE CAMBIO</div>
      </div>
      <h1 className='formJiraChange__title'>Solicitud de Cambio</h1>
      <div className='jiraChangeForm_menuFormContainer'>
        <nav className='jiraChangeForm_menu'>
          {menuItems.map((item) => (
            <button
              key={item.type}
              onClick={() => handleMenuClick(item.type)}
              style={{
                backgroundColor: focusedButton === item.type ? '#F2F4FF' : 'initial',
                color: focusedButton === item.type ? '#06f' : 'initial',
              }}
            >
              {item.label}
            </button>
          ))}
        </nav>

        <form
          className='formJiraChange_form'
          onSubmit={(e) =>
            handleSubmit(
              e,
              formData,
              isButtonEnabled,
              setIsSubmitting,
              handleClearForm,
              setChangeType,
              (newState) => {
                setPopupState(newState); // Usa directamente el setPopupState más reciente
              },
              setMessageSendingFailed,
              ticketId,
              [] // Array vacío si no hay archivos adjuntos
            )
          }
        >
          <div className='formJiraChange_section'>
            {Array.isArray(changeType) && changeType.includes('info') && (
              <GeneralInfoPeople formData={formData} handleInputChange={handleInputChange} />
            )}
            {Array.isArray(changeType) && changeType.includes('Organizational') && (
              <OrganizationalChangePeople
                formData={formData}
                ticketData={ticketData}
                handleInputChange={handleInputChange}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {Array.isArray(changeType) && changeType.includes('Salary') && (
              <SalaryChangePeople formData={formData} handleInputChange={handleInputChange} setErrors={setErrors} />
            )}
            {Array.isArray(changeType) && changeType.includes('Location') && (
              <LocationChangePeople formData={formData} handleInputChange={handleInputChange} setErrors={setErrors} />
            )}
            {Array.isArray(changeType) && changeType.includes('Contract') && (
              <ContractChangePeople formData={formData} handleInputChange={handleInputChange} setErrors={setErrors} />
            )}
            {Array.isArray(changeType) && changeType.includes('comments') && (
              <CommentsForm formData={formData} handleInputChange={handleInputChange} />
            )}
            {Array.isArray(changeType) && changeType.includes('others') && (
              <OthersChangePeople formData={formData} handleInputChange={handleInputChange} />
            )}
          </div>
          <div className='formJiraChangeButton_section'>
            <button className={`formJiraChangeButton`} type='submit'>
              Enviar
            </button>
            {isSubmitting && <div className='loader'></div>}
          </div>
        </form>
      </div>
      {popupState.isVisible && (
        <>
          <Popup type='success' message={popupState.message} onClose={handleCloseFeedbackPopup} />
        </>
      )}
    </section>
  );
};

export default FormPeopleMain;
