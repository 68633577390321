import React, { useEffect, useState, useRef } from 'react';
import arrowIcon from '../images/folder/arrow.svg';
import arrowIcon2 from '../images/folder/arrow.svg';
import peoplExIcon from '../images/mainPanel/knowledge.png';
import employeeFolderIcon from '../images/mainPanel/folder.png';
import peopleHubIcon from '../images/mainPanel/peoplehub.png';
import swpIcon from '../images/mainPanel/swp.png';
import adminIcon from '../images/mainPanel/admin.png';
import growthIcon from '../images/mainPanel/growth.png';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/mainPanel.css';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const MainPanel = ({ userInfoSSFF, onEmailChange, onPermissionsChange, idToken, jwtSSFF }) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [userPermissions, setUserPermissions] = useState({});
  const currentUserEmail = userInfoSSFF?.username || '';
  const [filteredApplications, setFilteredApplications] = useState([]);
  const hasFetchedUser = useRef(false);
  const navigate = useNavigate();
  const isLifeCycleExperience = userInfoSSFF?.department === 'LIFECYCLE EXPERIENCE (DE107212)';
  const isPeopleExperience = userInfoSSFF?.department === 'PEOPLE EXPERIENCE (DE101313)';
  const validationList = [
    'pablo.navarrofh@telefonica.com',
    'laura.guijarromolina@telefonica.com',
    'carmen.arevalo@telefonica.com',
    'mariajulia.reysanchez@telefonica.com',
    'david.alonsogarcia@telefonica.com',
    'nayra.romerosalas@telefonica.com',
  ];
  const isAdmin = validationList.includes(userInfoSSFF?.username);

  const application = [
    {
      id: 1,
      name: 'PeoplEx',
      description: 'Base de conocimiento',
      link: '/knowledgepage',
      icon: peoplExIcon,
      permission: 'view_peoplex',
      visible: true,
    },
    {
      id: 2,
      name: 'Carpeta del empleado',
      description: 'Consulta tus nóminas, certificados, etc.',
      link: '/folder',
      icon: employeeFolderIcon,
      permission: 'view_employee_folder',
      visible: true,
    },
    {
      id: 3,
      name: 'TalentCards',
      description: 'Consulta las fichas talento',
      link: '/talentCards',
      icon: peopleHubIcon,
      permission: 'view_talent_cards',
      visible: true,
    },
    {
      id: 5,
      name: 'Solicitudes',
      description: 'Gestiona tus solicitudes',
      link: '/form',
      icon: growthIcon,
      permission: 'view_digital_growth',
      visible: true,
    },
    {
      id: 6,
      name: 'Panel de administración',
      description: 'Administra los módulos y sus datos',
      link: '/adminPanel',
      icon: adminIcon,
      permission: 'admin',
      visible: isLifeCycleExperience || isPeopleExperience || isAdmin,
    },
  ];

  useEffect(() => {
    const fetchUserInfoData = async () => {
      try {
        const response = await fetch(`/userInfo`, {
          method: 'GET',
          headers: {
            'x-msal-Authorization': `Bearer ${idToken}`,
            'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          if (Array.isArray(data.userInfofromDB) && data.userInfofromDB.length === 0) {
            navigate('/knowledgepage');
          } else {
            const currentUser = data.userInfofromDB.find((user) => user.scope_value === currentUserEmail);
            if (currentUser) {
              setUserPermissions(currentUser);
              // Filtrar las aplicaciones basadas en permisos y visibilidad
              const filteredApps = application.filter((app) => app.visible && currentUser[app.permission] === true);
              setFilteredApplications(filteredApps);
            } else {
              console.error('User not found');
            }
          }
        } else {
          console.error('Error fetching user info:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    if (currentUserEmail) {
      fetchUserInfoData();
    }
  }, [currentUserEmail]);

  // Efecto separado para actualizar el email en el componente padre
  useEffect(() => {
    if (currentUserEmail) {
      onEmailChange(currentUserEmail);
    }
  }, [currentUserEmail, onEmailChange]);

  // Efecto separado para actualizar los permisos en el componente padre
  useEffect(() => {
    if (Object.keys(userPermissions).length > 0) {
      onPermissionsChange(userPermissions);
    }
  }, [userPermissions, onPermissionsChange]);

  const fetchIP = async () => {
    try {
      const response = await fetch(`/obtenerIP`, {
        method: 'GET',
        headers: {
          'x-msal-Authorization': `Bearer ${idToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
      } else {
        console.error('Error en la solicitud:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  };

  return (
    <section className='mainPanel'>
      <div className='applications__container'>
        {filteredApplications.map((app) => (
          <Link to={app.link} key={app.id} className='appItem__container'>
            <div className='appItem__info'>
              <div className='appItem__info--iconbox'>
                <img src={app.icon} className='appItem__info--icon' alt='app-icon' />
              </div>
              <h1 className='appItem__info--name'>{app.name}</h1>
              <h2 className='appItem__info--desc'>{app.description}</h2>
            </div>
            <div className='featured__contentbox__iconbox'>
              <div className='iconWidth'>
                <img src={arrowIcon} className='featured__contentbox__iconbox--iconArrowRight' alt='arrow-icon' />
                <img src={arrowIcon2} className='featured__contentbox__iconbox--iconArrowRight' alt='arrow-icon' />
              </div>
            </div>
          </Link>
        ))}
      </div>
      {/*       <button onClick={fetchIP}>Obtener IP</button>
       */}
    </section>
  );
};

export default MainPanel;
