import React, { useEffect, useState } from 'react';
import { validateFields } from '../validateFields';
import NewJobClassification from './newJobClassification';
import NewPosition from './newPosition';
import { RadioGroup, SwitchGroup } from '../inputs/radioGroup';
import elipse242 from '../../../../../../../../images/ellipse242.png';

export const OrganizationalChangePeople = ({ formData, handleInputChange, errors = {}, setErrors }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [showManagerInput, setShowManagerInput] = useState(false);
  const [showRoleChangeInput, setShowRoleChangeInput] = useState(formData.roleChange === 'Sí');
  const [showNewDutiesInput, setShowNewDutiesInput] = useState(formData.newDuties === 'Sí');
  const [showCategoryChangeInput, setShowCategoryChangeInput] = useState(formData.categoryChange === 'Sí');

  // Efectos para sincronizar estado visual con formData
  useEffect(() => {
    setShowRoleChangeInput(formData.roleChange === 'Sí');
    setShowNewDutiesInput(formData.newDuties === 'Sí');
    setShowCategoryChangeInput(formData.categoryChange === 'Sí');
  }, [formData.roleChange, formData.newDuties, formData.categoryChange]);

  // Clase dinámica para inputs
  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue) return 'formJiraChangeInputText prefilledBorder';
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios organizativos</div>
      <div className='formJiraChangeRadioGroup'>
        {/* Pregunta: ¿Se trata de una reorganización? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Se trata de una reorganización?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                value='Sí'
                checked={formData.organizationalChange === 'Sí'}
                onChange={() => handleInputChange('organizationalChange', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                value='No'
                checked={formData.organizationalChange === 'No'}
                onChange={() => handleInputChange('organizationalChange', 'No')}
              />
              No
            </label>
          </div>
        </div>
        {/* Pregunta: ¿Hay que crear nuevos departamentos? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Hay que crear nuevos departamentos?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                value='Sí'
                checked={formData.newDepartments === 'Sí'}
                onChange={() => handleInputChange('newDepartments', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                value='No'
                checked={formData.newDepartments === 'No'}
                onChange={() => handleInputChange('newDepartments', 'No')}
              />
              No
            </label>
          </div>
        </div>
        {/* Pregunta: ¿Quieres asignar a esta persona/s un nuevo responsable directo? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Quieres asignar a esta persona/s un nuevo responsable directo?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newManager'
                value='Sí'
                checked={formData.newManager === 'Sí'}
                onChange={() => handleInputChange('newManager', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newManager'
                value='No'
                checked={formData.newManager === 'No'}
                onChange={() => handleInputChange('newManager', 'No')}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: Nuevo responsable directo */}
        {formData.newManager === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              Nombre del nuevo responsable directo:
              <input
                type='text'
                placeholder='Responsable Directo'
                className='formJiraChangeInputText'
                value={formData.newAsigneeManager || ''}
                onChange={(e) => handleInputChange('newAsigneeManager', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Pregunta: ¿Se trata de un cambio de rol? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Se trata de un cambio de rol?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                value='Sí'
                checked={formData.roleChange === 'Sí'}
                onChange={() => {
                  setShowRoleChangeInput(true);
                  handleInputChange('roleChange', 'Sí');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                value='No'
                checked={formData.roleChange === 'No'}
                onChange={() => {
                  setShowRoleChangeInput(false);
                  handleInputChange('roleChange', 'No');
                  handleInputChange('roleChangeDetails', '');
                }}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: Detalles del cambio de rol */}
        {formData.roleChange === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              ¿Puedes indicarnos en qué consiste el cambio de rol:
              <input
                type='text'
                placeholder='Detalles del cambio de rol'
                className='formJiraChangeInputText'
                value={formData.roleChangeDetails}
                // value={formData.roleChangeDetails?.content?.[0]?.content?.[0]?.text || ''}
                onChange={(e) => handleInputChange('roleChangeDetails', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Pregunta: ¿Cambian las funciones de esta persona? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Cambian las funciones de esta persona?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDuties'
                value='Sí'
                checked={formData.newDuties === 'Sí'}
                onChange={() => {
                  setShowNewDutiesInput(true);
                  handleInputChange('newDuties', 'Sí');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDuties'
                value='No'
                checked={formData.newDuties === 'No'}
                onChange={() => {
                  setShowNewDutiesInput(false);
                  handleInputChange('newDuties', 'No');
                  handleInputChange('newDutiesDetails', '');
                }}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: Detalles de las nuevas funciones */}
        {formData.newDuties === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              ¿Puedes indicarnos en qué consisten esas nuevas funciones?:
              <input
                type='text'
                placeholder='Detalles de las nuevas funciones'
                className='formJiraChangeInputText'
                value={formData.newDutiesDetails}
                // value={formData.newDutiesDetails?.content?.[0]?.content?.[0]?.text || ''}
                onChange={(e) => handleInputChange('newDutiesDetails', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Pregunta: ¿Se trata de un cambio de categoría? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Se trata de un cambio de categoría?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='categoryChange'
                value='Sí'
                checked={formData.categoryChange === 'Sí'}
                onChange={() => {
                  setShowCategoryChangeInput(true);
                  handleInputChange('categoryChange', 'Sí');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='categoryChange'
                value='No'
                checked={formData.categoryChange === 'No'}
                onChange={() => {
                  setShowCategoryChangeInput(false);
                  handleInputChange('categoryChange', 'No');
                }}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: ¿Qué cambio de categoría quieres realizar? */}
        {formData.categoryChange === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              ¿Qué cambio de categoría quieres realizar?
              <input
                type='text'
                placeholder='Describe el cambio de categoría'
                className='formJiraChangeInputText'
                value={formData.categoryChangeDetails?.content?.[0]?.content?.[0]?.text || ''}
                onChange={(e) => handleInputChange('categoryChangeDetails', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Campo: HRBP y Unidad de negocio */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            HRBP:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData?.hrbp || ''}
              onChange={(e) => handleInputChange('hrbp', e.target.value)}
            />
          </label>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Unidad de negocio:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData?.businessUnit || ''}
              onChange={(e) => handleInputChange('businessUnit', e.target.value)}
            />
          </label>
        </div>
        {/* Campo: División y Departamento */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            División:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData?.division || ''}
              onChange={(e) => handleInputChange('division', e.target.value)}
            />
          </label>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Departamento:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.department || ''}
              onChange={(e) => handleInputChange('department', e.target.value)}
            />
          </label>
        </div>
        {/* Campo: Centro de coste y Resultados objetivos */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Centro de coste:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.costCenter || ''}
              onChange={(e) => handleInputChange('costCenter', e.target.value)}
            />
          </label>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Resultados objetivos:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.objectivesResult || ''}
              onChange={(e) => handleInputChange('objectivesResult', e.target.value)}
            />
          </label>
        </div>
        <div className='formArticleSection'>
          <RadioGroup
            label='¿El cambio implica la creación de una nueva Job Classification?'
            name='newJobClasification'
            options={[
              { label: 'Sí', value: 'Sí' },
              { label: 'No', value: 'No' },
            ]}
            formData={formData}
            handleInputChange={handleInputChange}
          />
          <NewJobClassification formData={formData} handleInputChange={handleInputChange} showForm={formData.newJobClasification === 'Sí'} />
        </div>

        {/* Nueva Job Classification creada */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>Nueva Job Classification creada</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='jcCreated'
                value='Sí'
                checked={formData.jcCreated === 'Sí'}
                onChange={() => handleInputChange('jcCreated', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='jcCreated'
                value='No'
                checked={formData.jcCreated === 'No'}
                onChange={() => handleInputChange('jcCreated', 'No')}
              />
              No
            </label>
          </div>
        </div>

        {/* Job Classification / Puesto */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Job Classification:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.jobClassification || ''}
              onChange={(e) => handleInputChange('jobClassification', e.target.value)}
            />
          </label>

          {/* Rol / Perfil Global */}
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Rol / Perfil Global:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.globalRole || ''}
              onChange={(e) => handleInputChange('globalRole', e.target.value)}
            />
          </label>
        </div>

        {/* Categoría */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Categoría:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.category || ''}
              onChange={(e) => handleInputChange('category', e.target.value)}
            />
          </label>

          {/* Código Benchmark */}
          <label className='formJiraChangeLabelRadio'>
            <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
            Código Benchmark:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={formData.benchmarkCode || ''}
              onChange={(e) => handleInputChange('benchmarkCode', e.target.value)}
            />
          </label>
        </div>

        {/* Pregunta: ¿El cambio implica la creación de una nueva posición? */}
        <div className='formArticleSection'>
          <RadioGroup
            label='¿El cambio implica la creación de una nueva posición?'
            name='newJobPosition'
            options={[
              { label: 'Sí', value: 'Sí' },
              { label: 'No', value: 'No' },
            ]}
            formData={formData}
            handleInputChange={handleInputChange}
          />
          <NewPosition formData={formData} handleInputChange={handleInputChange} showForm={formData.newJobPosition === 'Sí'} />
        </div>

        {/* Nueva posición creada */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />

          <label className='formJiraChangeLabelRadio'>Nueva posición creada</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newPositionCreated'
                value='Sí'
                checked={formData.newPositionCreated === 'Sí'}
                onChange={() => handleInputChange('newPositionCreated', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newPositionCreated'
                value='No'
                checked={formData.newPositionCreated === 'No'}
                onChange={() => handleInputChange('newPositionCreated', 'No')}
              />
              No
            </label>
          </div>
        </div>

        {/* Empleado asignado a la nueva posición */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>Empleado asignado a la nueva posición</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='employeeAssignedToNewPosition'
                value='Sí'
                checked={formData.employeeAssignedToNewPosition === 'Sí'}
                onChange={() => handleInputChange('employeeAssignedToNewPosition', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='employeeAssignedToNewPosition'
                value='No'
                checked={formData.employeeAssignedToNewPosition === 'No'}
                onChange={() => handleInputChange('employeeAssignedToNewPosition', 'No')}
              />
              No
            </label>
          </div>
        </div>

        {/* Código de la posición */}
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabelRadio'>
            Código de la posición:
            <input
              type='text'
              placeholder='Código de la posición'
              value={formData.positionCode || ''}
              onChange={(e) => handleInputChange('positionCode', e.target.value)}
            />
          </label>
        </div>

        {/* ¿Es necesario realizar algún cambio en la posición? */}
        <div className='formJiraChangeLabelRadio'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          <label className='formJiraChangeLabelRadio'>¿Es necesario realizar algún cambio en la posición?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='positionChangeRequired'
                value='Sí'
                checked={formData.positionChangeRequired === 'Sí'}
                onChange={() => handleInputChange('positionChangeRequired', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='positionChangeRequired'
                value='No'
                checked={formData.positionChangeRequired === 'No'}
                onChange={() => handleInputChange('positionChangeRequired', 'No')}
              />
              No
            </label>
          </div>
        </div>

        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel'>
            Comentarios cambios en la posición:
            <textarea
              placeholder='Comentarios sobre los cambios en la posición'
              className='formJiraChangeInputText'
              value={formData.positionChangeComments}
              onChange={(e) => handleInputChange('positionChangeComments', e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};
