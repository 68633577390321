import React, { useEffect, useState } from 'react';
import { LabelInput } from '../inputs/labelInput';
import { validateFields } from '../validateFields';

const NewPosition = ({ formData, handleInputChange, showForm }) => {
  const [errors, setErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const fields = {
      positionTitle: formData.positionTitle,
      positionStartDate: formData.positionStartDate,
      positionCompany: formData.positionCompany,
      jobClassification: formData.jobClassification,
      businessUnit: formData.businessUnit,
      division: formData.division,
      department: formData.department,
      costCenter: formData.costCenter,
      globalRole: formData.globalRole,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [formData]);

  const getInputClass = (fieldValue, fieldName) => {
    return errors[fieldName] ? 'formJiraChangeInputText_short errorBorder' : 'formJiraChangeInputText_short';
  };

  if (!showForm) return null;

  return (
    <>
      {/* Datos del empleado */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeName'>
          {' '}
          Nombre y apellidos del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeName} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeEmail'>
          {' '}
          Email del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeEmail} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeSSFFId'>
          {' '}
          Id del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeSSFFId} readOnly />
      </div>
      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Título de la posición:</label>
        <input
          type='text'
          value={formData.positionTitle || ''}
          onChange={(e) => handleInputChange('positionTitle', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Fecha de inicio de la posición:</label>
        <input
          type='date'
          value={formData.positionStartDate || ''}
          onChange={(e) => handleInputChange('positionStartDate', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Empresa de la posición:</label>
        <input
          type='text'
          value={formData.positionCompany || ''}
          onChange={(e) => handleInputChange('positionCompany', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Job Classification / Puesto:</label>
        <input
          type='text'
          value={formData.jobClassification || ''}
          onChange={(e) => handleInputChange('jobClassification', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Unidad de negocio:</label>
        <input
          type='text'
          value={formData.businessUnit || ''}
          onChange={(e) => handleInputChange('businessUnit', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>División:</label>
        <input
          type='text'
          value={formData.division || ''}
          onChange={(e) => handleInputChange('division', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Departamento:</label>
        <input
          type='text'
          value={formData.department || ''}
          onChange={(e) => handleInputChange('department', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Centro de Coste:</label>
        <input
          type='text'
          value={formData.costCenter || ''}
          onChange={(e) => handleInputChange('costCenter', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>

      <div className='formGroup'>
        <label className='formJiraChangeLabel'>Rol / Perfil Global:</label>
        <input
          type='text'
          value={formData.globalRole || ''}
          onChange={(e) => handleInputChange('globalRole', e.target.value)}
          className='formJiraChangeInputText'
        />
      </div>
    </>
  );
};

export default NewPosition;
