export const getUserInfoSSFF = async (token = null) => {
  let idToken = token || localStorage.getItem('idToken');

  if (!idToken) {
    throw new Error('No se encontró el idToken en localStorage');
  }
  try {
    const response = await fetch('/user', {
      method: 'GET',
      headers: {
        'x-msal-Authorization': `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      console.error('Response not OK');
      throw new Error('Fallo en la solicitud de usuario');
    }

    const responseUserData = await response.json();
    return responseUserData;
  } catch (error) {
    throw new Error('Error en la solicitud de usuario ' + error.message);
  }
};

export const getUsersInfoSSFF = async () => {
  try {
    const response = await fetch(`/users`, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Fallo en la solicitud de usuario');
    }
    const responseUsersData = await response.json();
    return responseUsersData;
  } catch (error) {
    throw new Error('Error en la solicitud de usuario ' + error.message);
  }
};

// FIX - para que es esta funcion?
export const getUserInfoSSFFId = async (userId) => {
  try {
    const response = await fetch(`http://localhost:3000/sf/managerId?id=${userId}`, {
      method: 'GET',
    });
    if (!response.ok) {
      console.error('Response not OK');
      throw new Error('Fallo en la solicitud de usuario');
    }

    const responseUserData = await response.json();

    return responseUserData;
  } catch (error) {
    throw new Error('Error en la solicitud de usuario ' + error.message);
  }
};
