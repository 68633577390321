import React, { useEffect, useState } from 'react';
import { validateFields } from '../formValidate';
import elipse242 from '../../../../../../images/ellipse242.png';

export const LocationChange = ({ city, country, building, floor, setCity, setCountry, setBuilding, setFloor, errors, setErrors }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  // Listado de paises sacado de PeopleCore - Legal Entities
  const countryOptions = [
    'Alemania',
    'Argentina',
    'Austria',
    'Bélgica',
    'Bolivia',
    'Brasil',
    'Bulgaria',
    'Chile',
    'Colombia',
    'Croacia',
    'Dinamarca',
    'Ecuador',
    'España',
    'Estados Unidos',
    'Estonia',
    'Francia',
    'Grecia',
    'Guatemala',
    'Holanda',
    'Hong Kong',
    'Hungría',
    'India',
    'Irlanda',
    'Italia',
    'Luxemburgo',
    'México',
    'Noruega',
    'Panamá',
    'Perú',
    'Polonia',
    'Portugal',
    'Puerto Rico',
    'Reino Unido',
    'República Dominicana',
    'Serbia',
    'Suecia',
    'Suiza',
    'Uruguay',
    'USA',
    'Venezuela',
  ];

  useEffect(() => {
    setInitialValues({
      city,
      country,
      building,
      floor,
    });
  }, []);

  useEffect(() => {
    const fields = {
      city,
      country,
      building,
      floor,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [city, country, building, floor]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios de localización</div>
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.city && '*'} Ciudad:
          <input type='text' className={getInputClass(city, 'city')} value={city} onChange={(e) => setCity(e.target.value)} placeholder='Ciudad...' />
        </label>
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.country && '*'} País:
          <select className={getInputClass(country, 'country')} value={country} onChange={(e) => setCountry(e.target.value)}>
            <option value='' disabled>
              Seleccione un país...
            </option>
            {countryOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.building && '*'} Edificio:
          <input
            type='text'
            className={getInputClass(building, 'building')}
            value={building}
            onChange={(e) => setBuilding(e.target.value)}
            placeholder='Edificio...'
          />
        </label>
        <label className='formJiraChangeLabel_bothIndividual'>
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          {errors.floor && '*'} Planta:
          <input
            type='text'
            className={getInputClass(floor, 'floor')}
            value={floor}
            onChange={(e) => setFloor(e.target.value)}
            placeholder='Planta...'
          />
        </label>
      </div>
    </div>
  );
};
