import React, { useEffect, useState } from 'react';
import { validateFields } from '../validateFields';
import elipse242 from '../../../../../../../../images/ellipse242.png';

export const ContractChangePeople = ({ formData, handleInputChange, errors = {}, setErrors }) => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    // Validar campos al iniciar y al cambiar
    const fields = {
      contractChange: formData.contractChange || '',
      hours: formData.hours,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [formData, setErrors]);

  const getInputClass = (fieldValue, fieldName) => {
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios de contrato</div>

      {/* Campo: Tipo de Contrato */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>
          {errors.contractChange && '*'}
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          Tipo de Contrato:
        </label>
        <select
          className={getInputClass(formData.contractChange, 'contractChange')}
          value={formData.contractChange || ''}
          onChange={(e) => handleInputChange('contractChange', e.target.value)}
        >
          <option value=''>Seleccione un tipo de contrato</option>
          <option value='Indefinido'>Indefinido</option>
        </select>
      </div>

      {/* Campo: Horas/Semana */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>
          {errors.hours && '*'}
          <img src={elipse242} alt='circle-icon' className='circle-icon-change' />
          Horas/Semana:
        </label>
        <select
          className={getInputClass(formData.hours, 'hours')}
          value={formData.hours || ''}
          onChange={(e) => handleInputChange('hours', e.target.value)}
        >
          <option value=''>Seleccione horas por semana</option>
          <option value='37,5h'>37,5h</option>
          <option value='40h'>40h</option>
        </select>
      </div>
    </div>
  );
};
