const Loan = () => {
  return (
    <>
      <iframe
        width='100%'
        height='100%'
        src='https://forms.office.com/e/aDV0asSz6y?embed=true'
        frameborder='0'
        marginwidth='0'
        marginheight='0'
        style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        msallowfullscreen
        title='loanForm'
      ></iframe>
    </>
  );
};

export default Loan;
