import React, { useState } from 'react';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import Popup from '../changeForm/formManager/components/popUp';
import templateDocIcon from '../../../images/jira/Factura_Plan_Documento_1Light.png';
import bajaVoluntariaTemplate from '../../../docs/MODELO_COMUNICACION_DE_BAJA_VOLUNTARIA.docx';
import excCuidadoFamiliarTemplate from '../../../docs/MODELO_COMUNICACION_DE_EXCEDENCIA_CUIDADO_FAMILIAR.docx';
import excCuidadoMenorTemplate from '../../../docs/MODELO_COMUNICACION_DE_EXCEDENCIA_CUIDADO_MENOR.docx';
import excForzosaTemplate from '../../../docs/MODELO_COMUNICACION_DE_EXCEDENCIA_FORZOSA.docx';
import excVoluntariaTemplate from '../../../docs/MODELO_COMUNICACION_DE_EXCEDENCIA_VOLUNTARIA.docx';
import voluntaryLeaveTypes from '../../../docs/ModalidadesBajaVoluntaria.pdf';

const TerminationDetailsSection = ({
  terminationDate,
  onTerminationDateChange,
  terminationType,
  onTerminationTypeChange,
  extraInfo,
  duration,
  durationUnit,
  onDurationChange,
  onDurationUnitChange,
  showTerminationDateRequired,
  showTerminationDateError,
  showTerminationTypeRequired,
  showDurationRequired,
  showPenalizationDisclaimer,
  userInfoSSFF,
}) => {
  const today = new Date().toISOString().split('T')[0];
  const [showDurationWarning, setShowDurationWarning] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTemplateMessage, setShowTemplateMessage] = useState(false);
  const [displayedPopups, setDisplayedPopups] = useState(new Set());
  const [selectedTerminationType, setSelectedTerminationType] = useState('');

  const templates = {
    'Baja voluntaria': bajaVoluntariaTemplate,
    'Excedencia por cuidado de menor': excCuidadoMenorTemplate,
    'Excedencia por cuidado de familiar dependiente': excCuidadoFamiliarTemplate,
    'Excedencia voluntaria': excVoluntariaTemplate,
    'Excedencia forzosa': excForzosaTemplate,
  };

  const handleDownloadTemplate = async () => {
    const templateUrl = templates[terminationType];
    if (!templateUrl) return alert('Por favor, selecciona un tipo de baja.');

    try {
      // Cargar la plantilla
      const response = await fetch(templateUrl);
      const templateBuffer = await response.arrayBuffer();

      // Configurar Docxtemplater
      const zip = new PizZip(templateBuffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      // Obtener la fecha actual
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.toLocaleString('es-ES', { month: 'long' }); // Nombre del mes en español
      const year = currentDate.getFullYear();

      // Mapeo de código de empresa al nombre
      const companyMapping = {
        '0250_ESP': 'TSA',
        '0024': 'TINDI',
        1412: 'TGS',
        1970: 'TSC',
        2205: 'CS2',
        1583: 'TOI',
        1343: 'TCE',
        1893: 'Telxius Cable',
        1702: 'Telxius Telecom',
        1017: 'T. Hispam',
      };
      // Obtener el código y mapear al nombre de la empresa
      const companyCode = userInfoSSFF?.custom03 || ''; // Ajustar según tus datos
      const company = companyMapping[companyCode] || companyCode; // Si no está en el mapeo, usa el código original

      // Autocompletar datos
      doc.setData({
        nombreYApellidos: userInfoSSFF?.displayName || 'NOMBRE Y APELLIDOS',
        dia: day,
        mes: month,
        ano: year,
        compania: company,
      });

      // Renderizar el documento
      doc.render();

      // Generar el archivo final
      const blob = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, `${terminationType}.docx`);
    } catch (error) {
      console.error('Error al generar el documento:', error);
      alert('Hubo un error al generar el documento.');
    }
  };

  // Función para obtener el mensaje del popup dependiendo del tipo de baja
  const getPopupMessage = () => {
    switch (selectedTerminationType) {
      case 'Baja voluntaria':
        return 'Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de al menos 15 días naturales o el que se indique en tu contrato. Antes de enviar tu solicitud de baja, es necesario que adjuntes un documento escrito en el que solicites la Baja voluntaria, firmado por ti. Puedes encontrar la plantilla correspondiente a continuación. Asegúrate de completar todos los campos necesarios antes de continuar con el proceso.';
      case 'Excedencia por cuidado de menor':
        return 'Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de al menos 15 días naturales o el que se indique en tu contrato. Antes de enviar tu solicitud de baja, es necesario que adjuntes un documento escrito en el que solicites la excedencia por cuidado de menor, firmado por ti. Puedes encontrar la plantilla correspondiente a continuación. Asegúrate de completar todos los campos necesarios antes de continuar con el proceso. Recuerda adjuntar también la documentación que acredite la situación personal o familiar que te permite solicitar este tipo de excedencias.';
      case 'Excedencia por cuidado de familiar dependiente':
        return 'Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de al menos 15 días naturales o el que se indique en tu contrato. Antes de enviar tu solicitud de baja, es necesario que adjuntes un documento escrito en el que solicites la excedencia por cuidado de familiar dependiente, firmado por ti. Puedes encontrar la plantilla correspondiente a continuación. Asegúrate de completar todos los campos necesarios antes de continuar con el proceso. Recuerda adjuntar también la documentación que acredite la situación personal o familiar que te permite solicitar este tipo de excedencias.';
      case 'Excedencia voluntaria':
        return 'Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de al menos 15 días naturales o el que se indique en tu contrato. Antes de enviar tu solicitud de baja, es necesario que adjuntes un documento escrito en el que solicites la excedencia voluntaria, firmado por ti. Puedes encontrar la plantilla correspondiente a continuación. Asegúrate de completar todos los campos necesarios antes de continuar con el proceso.';
      case 'Excedencia forzosa':
        return 'Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de al menos 15 días naturales o el que se indique en tu contrato. Antes de enviar tu solicitud de baja, es necesario que adjuntes un documento escrito en el que solicites la excedencia forzosa por desempeño en cargo público, firmado por ti. Puedes encontrar la plantilla correspondiente a continuación. Asegúrate de completar todos los campos necesarios antes de continuar con el proceso. Recuerda adjuntar también la documentación que acredite la situación personal o familiar que te permite solicitar este tipo de excedencias.';
      default:
        return 'Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de al menos 15 días naturales o el que se indique en tu contrato. Antes de enviar tu solicitud de baja, es necesario que adjuntes un documento escrito en el que solicites la Baja voluntaria sin excedencia, firmado por ti. Puedes encontrar la plantilla correspondiente a continuación. Asegúrate de completar todos los campos necesarios antes de continuar con el proceso.';
    }
  };

  //Handle de validación de la duración de las excedencias.
  const handleDurationAndUnitChange = (e, type) => {
    const isCuidadoFamiliar = selectedTerminationType === 'Excedencia por cuidado de familiar dependiente';
    const maxDuration = isCuidadoFamiliar ? 2 : 5; // 2 años para Excedencia por cuidado de familiar dependiente, 5 años para los demás

    if (type === 'duration') {
      const newDuration = e.target.value;
      onDurationChange(e);

      // Verifica si la duración es mayor a 5 años o 60 meses
      if ((durationUnit === 'años' && newDuration > maxDuration) || (durationUnit === 'meses' && newDuration > maxDuration * 12)) {
        setShowDurationWarning(true); // Muestra el warning
      } else {
        setShowDurationWarning(false);
      }
    } else if (type === 'unit') {
      const newDurationUnit = e.target.value;
      onDurationUnitChange(e);

      // Verifica si la duración es mayor a 5 años o 60 meses con la nueva unidad
      if ((newDurationUnit === 'años' && duration > maxDuration) || (newDurationUnit === 'meses' && duration > maxDuration * 12)) {
        setShowDurationWarning(true); // Muestra el warning
      } else {
        setShowDurationWarning(false);
      }
    }
  };

  const getWarningMessage = () => {
    return selectedTerminationType === 'Excedencia por cuidado de familiar dependiente'
      ? 'El máximo periodo que puedes solicitar para esta excedencia es de 2 años.'
      : 'El máximo periodo que puedes solicitar para una excedencia es de 5 años.';
  };

  // Maneja la selección de tipo de baja
  const handleTerminationTypeChange = (e) => {
    const selectedType = e.target.value;
    onTerminationTypeChange(e);
    setSelectedTerminationType(selectedType);

    // Solo muestra el popup si el tipo no está en el conjunto de popups mostrados
    if (!displayedPopups.has(selectedType)) {
      setShowPopup(true);
      setDisplayedPopups((prevSet) => new Set(prevSet).add(selectedType));
    }
    // Mostrar el mensaje
    setShowTemplateMessage(true);
  };

  // Cerrar el popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className='formJiraEndSection'>
        <div className='formJiraEndSection_type'>
          <div className='formJiraEndLabel_typeinfo"'>
            <label className='formJiraEndLabel_type'>
              Tipo de baja
              {showTerminationTypeRequired && <p className='formJiraEndLabel__required'>Este es un campo requerido</p>}
            </label>
          </div>
          <div className='formJiraEndSection_moreInfo'>
            <a href={voluntaryLeaveTypes} target='_blank' rel='noopener noreferrer' className='infoButtonContainer'>
              <button className='infoButton' type='button'>
                Más información sobre los tipos de baja
              </button>
            </a>
          </div>
        </div>
        <div className='formJiraEndCheckboxGroup'>
          <div className='terminationOption'>
            <label className='forJiraEndCheckboxLabel'>
              <input
                className='terminationRadioInput'
                type='radio'
                name='terminationType'
                value='Baja voluntaria'
                checked={terminationType === 'Baja voluntaria'}
                onChange={handleTerminationTypeChange}
              />
              Baja voluntaria sin excedencia
            </label>
          </div>

          <div className='terminationOption'>
            <label className='forJiraEndCheckboxLabel'>
              <input
                className='terminationRadioInput'
                type='radio'
                name='terminationType'
                value='Excedencia por cuidado de menor'
                checked={terminationType === 'Excedencia por cuidado de menor'}
                onChange={handleTerminationTypeChange}
              />
              Excedencia por cuidado de menor
            </label>
          </div>

          <div className='terminationOption'>
            <label className='forJiraEndCheckboxLabel'>
              <input
                className='terminationRadioInput'
                type='radio'
                name='terminationType'
                value='Excedencia por cuidado de familiar dependiente'
                checked={terminationType === 'Excedencia por cuidado de familiar dependiente'}
                onChange={handleTerminationTypeChange}
              />
              Excedencia por cuidado de familiar dependiente
            </label>
          </div>

          <div className='terminationOption'>
            <label className='forJiraEndCheckboxLabel'>
              <input
                className='terminationRadioInput'
                type='radio'
                name='terminationType'
                value='Excedencia voluntaria'
                checked={terminationType === 'Excedencia voluntaria'}
                onChange={handleTerminationTypeChange}
              />
              Excedencia voluntaria
            </label>
          </div>

          <div className='terminationOption'>
            <label className='forJiraEndCheckboxLabel'>
              <input
                className='terminationRadioInput'
                type='radio'
                name='terminationType'
                value='Excedencia forzosa'
                checked={terminationType === 'Excedencia forzosa'}
                onChange={handleTerminationTypeChange}
              />
              Excedencia forzosa por desempeño en cargo público
            </label>
          </div>
        </div>

        {/* Mostrar el mensaje y el icono cuando se selecciona un tipo */}
        {showTemplateMessage && (
          <div className='formJiraTemplateMessage'>
            <p className='formJiraTemplateMessageType'>
              Descarga la plantilla del documento para el tipo de baja: <strong>{terminationType}</strong>
            </p>
            {terminationType && (
              <img
                src={templateDocIcon}
                alt='document template icon'
                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                onClick={handleDownloadTemplate} // Función de descarga personalizada
              />
            )}
          </div>
        )}
      </div>

      {/* Mostrar el popup cuando el usuario seleccione un tipo de baja */}
      {showPopup && (
        <Popup
          type='failure' // Puedes personalizar el tipo según tu necesidad
          message={getPopupMessage()}
          onClose={handleClosePopup}
        />
      )}

      {extraInfo && (
        <div className='formJiraEndSection'>
          <label className='formJiraEndLabel'>
            {extraInfo}
            {showDurationRequired && <p className='formJiraEndLabel__required'>Este es un campo requerido</p>}
          </label>

          <div className='formJiraEndDuration'>
            <input
              type='number'
              placeholder='Nº'
              className='formJiraEndInputText_short'
              value={duration}
              onChange={(e) => handleDurationAndUnitChange(e, 'duration')}
              max={durationUnit === 'meses' ? 60 : undefined} // Limita a 60 meses (5 años)
              min={0}
            />

            <div className='formJiraEndRadioDuration'>
              <label>
                <input
                  className='terminationRadioInput'
                  type='radio'
                  name='durationUnit'
                  value='meses'
                  checked={durationUnit === 'meses'}
                  onChange={(e) => handleDurationAndUnitChange(e, 'unit')}
                />
                Meses
              </label>

              <label>
                <input
                  className='terminationRadioInput'
                  type='radio'
                  name='durationUnit'
                  value='años'
                  checked={durationUnit === 'años'}
                  onChange={(e) => handleDurationAndUnitChange(e, 'unit')}
                />
                Años
              </label>
            </div>
          </div>
          {/* Mensaje de advertencia si la duración es mayor que 5 años */}
          {showDurationWarning && (
            <div className='formJiraEndSection'>
              <p className='formJiraEndLabel__required'>{getWarningMessage()}</p>
            </div>
          )}
        </div>
      )}
      <div className='formJiraEndSection'>
        <label className='formJiraEndLabel' htmlFor='terminationDate'>
          Fecha de baja
          {showTerminationDateRequired && <p className='formJiraEndLabel__required'>Este es un campo requerido</p>}
        </label>
        <input
          type='date'
          placeholder='Escriba la fecha'
          className='formJiraEndInputText_short'
          value={terminationDate}
          onChange={onTerminationDateChange}
          min={today}
        />
      </div>
      {showPenalizationDisclaimer && (
        <div className='formJiraEndSection'>
          <p className='formJiraEndLabel__required'>
            Importante: Recuerda que para solicitar tu baja de la empresa es necesario un preaviso de 15 días naturales. En el caso de solicitar tu
            baja con una fecha inferior a los 15 días, se podrán detraer los días que falten de preaviso de la liquidación correspondiente. También es
            posible que la documentación que debes firmar para la gestión de la baja no esté disponible a tiempo, dado que es necesario ese tiempo de
            preaviso para poder completar el proceso adecuadamente. Gracias por tu colaboración.
          </p>
        </div>
      )}
    </>
  );
};

export default TerminationDetailsSection;
