import React from 'react';
import { LabelInput } from './inputs/labelInput';

const CommentsForm = ({ comments, setComments, formData }) => {
  return (
    <div className='formSection'>
      <h2 className='formSection__title'>Comentarios adicionales</h2>
      <LabelInput label='Comentarios Manager:' value={formData.comments} onChange={(e) => setComments(e.target.value)} type='textarea' />
    </div>
  );
};

export default CommentsForm;
