import { useState, useEffect } from 'react';
import addIcon from '../../images/adminPanel/add.png';
import editIcon from '../../images/adminPanel/edit.png';
import filterIcon from '../../images/adminPanel/filter.png';
import verIcon from '../../images/adminPanel/ver.png';
import '../styles/subcategoryList.css';

const normalizeString = (str) => {
  return str
    .toString()
    .normalize('NFD') // Descompone caracteres acentuados
    .replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
    .toLowerCase(); // Convierte a minúsculas
};

const SubcategoryList = ({ items, isLoading, reloadData }) => {
  let filterOptions = [];
  const [filterKey, setFilterKey] = useState(filterOptions[0]?.value || ''); // Valor inicial del filtro
  const [filterValue, setFilterValue] = useState(''); // Valor del filtro
  const [filterVisible, setFilterVisible] = useState(false); // Visibilidad del filtro
  const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Recuperar las categorías desde localStorage
    const storedCategories = localStorage.getItem('categories');
    if (storedCategories) {
      setCategories(JSON.parse(storedCategories)); // Parsear y guardar en el estado
    }
  }, []); // Ejecutar solo al montar el componente

  useEffect(() => {
    if (filterOptions.length > 0 && !filterOptions.some((opt) => opt.value === filterKey)) {
      setFilterKey(filterOptions[0].value);
    }
  }, [filterOptions]);

  // Opciones del filtro
  filterOptions = [
    { label: 'Nombre', value: 'subcategory_name' },
    { label: 'Categoría', value: 'category_name' },
    { label: 'Estado', value: 'is_active' },
  ];

  // Eliminar duplicados basados en subcategory_id
  const uniqueItems = items.filter((item, index, self) => index === self.findIndex((t) => t.subcategory_id === item.subcategory_id));

  // Filtrar los elementos según el valor introducido por el usuario
  const filteredItems = uniqueItems
    .sort((a, b) => a.subcategory_id - b.subcategory_id) // Ordenar por ID de forma ascendente
    .filter((item) => {
      if (!filterKey || !filterValue) return true;
      const field = item[filterKey]?.toString();
      if (filterKey === 'is_active') {
        const Estado = item.is_active ? 'activo' : 'desactivado';
        return normalizeString(Estado).includes(normalizeString(filterValue));
      }
      return normalizeString(field).includes(normalizeString(filterValue));
    });

  const filterToggle = () => {
    setFilterVisible(!filterVisible); // Alternar visibilidad del filtro
  };

  const handleViewClick = (item) => {
    setSelectedSubcategory(item);
    setIsEditMode(false);
    setIsPopupVisible(true);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch('/updateSubcategories', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...selectedSubcategory, ...formData }),
      });
      if (response.ok) {
        setIsPopupVisible(false);
        reloadData();
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      alert('Error al actualizar la categoría.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData({ ...formData, subcategory_img: reader.result.split(',')[1] });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleEditClick = (item) => {
    setSelectedSubcategory(item);
    setFormData({
      subcategory_name: item.subcategory_name,
      is_active: item.is_active,
      subcategory_img: item.subcategory_img || null,
      // category_name: item.category_name,
    });
    setIsEditMode(true);
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setSelectedSubcategory(null);
    setIsEditMode(false);
    setIsAddPopupVisible(false);
  };

  const handleAddClick = () => {
    setIsAddPopupVisible(true);
    setFormData({
      subcategory_name: '',
      category_id: '',
      subcategory_img: null,
      is_active: false,
      article_id: '',
    });
  };

  const handleAddSubcategory = async () => {
    try {
      const response = await fetch('/addSubcategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Enviar todos los datos, incluida la imagen en base64
      });
      if (response.ok) {
        setIsAddPopupVisible(false);
        reloadData(); // Recargar datos después de añadir
      } else {
        alert('Error al añadir la subcategoría.');
      }
    } catch (error) {
      console.error('Error al añadir la subcategoría:', error);
      alert('Error al añadir la subcategoría.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ display: 'flex', padding: '2em 0 0 2em', height: '80px', gap: '1em', alignItems: 'center', alignContent: 'left', width: '100%' }}>
        <img
          src={addIcon}
          alt='Añadir'
          className='itemList__icon'
          style={{
            height: '18px',
            width: '18px',
          }}
          onClick={handleAddClick}
        />
        {/* Contenedor del filtro */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em', height: '60px', gap: '1em' }}>
          <img
            src={filterIcon}
            alt='Toggle Filter'
            onClick={filterToggle}
            style={{
              border: 'none',
              marginTop: '1em',
              cursor: 'pointer',
              height: '18px',
            }}
          />
          {/* Contenedor de los filtros */}
          <div className={`filter-container ${filterVisible ? 'visible' : 'hidden'}`}>
            <select className='form-select subcategoriesSelect' value={filterKey} onChange={(e) => setFilterKey(e.target.value)}>
              {filterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              type='text'
              className='form-control subcategoriesInput'
              placeholder='Buscar...'
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)} // Actualizar el valor del filtro
            />
          </div>
        </div>
      </div>

      {/* Tabla de datos */}
      {isLoading ? (
        <div className='spinner-box'>
          <div className='pulse-container'>
            <div className='pulse-bubble pulse-bubble-1'></div>
            <div className='pulse-bubble pulse-bubble-2'></div>
            <div className='pulse-bubble pulse-bubble-3'></div>
          </div>
        </div>
      ) : filteredItems.length > 0 ? (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '5%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Id
              </th>
              <th scope='col' style={{ width: '50%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Nombre Subcategoría
              </th>
              <th scope='col' style={{ width: '8%', textAlign: 'start', fontWeight: 'bold' }}>
                Categoría
              </th>
              <th scope='col' style={{ width: '8%', textAlign: 'center', fontWeight: 'bold' }}>
                Estado
              </th>
              <th style={{ width: '5%' }} scope='col'></th>
              <th style={{ width: '5%' }} scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, index) => (
              <tr key={index}>
                {/* Información del artículo */}
                <td style={{ width: '5%', textAlign: 'start', paddingLeft: '2em' }}>{item.subcategory_id}</td>
                <td style={{ width: '50%', textAlign: 'start', paddingLeft: '2em' }}>{item.subcategory_name}</td>
                <td style={{ width: 'fit-content', textAlign: 'start' }}>{item.category_name}</td>
                <td style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      fontSize: '11px',
                      padding: '3px 5px',
                      borderRadius: '5px',
                      border: item.is_active ? '1px solid #06f' : '1px solid grey',
                      backgroundColor: item.is_active ? '#06f' : 'white',
                      color: item.is_active ? 'white' : 'black',
                      width: '90%',
                      cursor: 'auto',
                    }}
                  >
                    {item.is_active ? 'Activo' : 'Desactivado'}
                  </button>
                </td>
                {/* Acciones */}
                <td style={{ width: '5%' }}>
                  <img src={verIcon} alt='Ver' className='itemList__icon' onClick={() => handleViewClick(item)} />
                </td>
                <td style={{ width: '5%' }}>
                  <img src={editIcon} alt='Editar' className='itemList__icon' onClick={() => handleEditClick(item)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '50%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Nombre Subcategoría
              </th>
              <th scope='col' style={{ width: '8%', textAlign: 'start', fontWeight: 'bold' }}>
                Categoría
              </th>
              <th scope='col' style={{ width: '8%', textAlign: 'center', fontWeight: 'bold' }}>
                Estado
              </th>
              <th style={{ width: '5%' }} scope='col'></th>
              <th style={{ width: '5%' }} scope='col'></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      )}
      {/* Popup */}
      {isPopupVisible && selectedSubcategory && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button
                  onClick={handleClosePopup}
                  style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em' }}
                >
                  &times;
                </button>
              </div>
              <div className='catPopup__infoContainer'>
                {isEditMode ? (
                  <form>
                    <div className='mb-3'>
                      <div className='mb-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <label htmlFor='subcategoryImage' className='form-label'>
                          <strong>Imagen de la subcategoría:</strong>
                        </label>
                        {formData.subcategory_img && (
                          <img
                            src={`data:image/jpeg;base64,${formData.subcategory_img}`}
                            alt='Imagen actual'
                            className='img-thumbnail'
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                          />
                        )}
                      </div>
                      <small>Tamaño requerido: 693x390</small>
                      <input
                        type='file'
                        className='form-control'
                        id='subcategoryImage'
                        name='subcategory_img'
                        onChange={handleFileChange}
                        accept='image/*'
                      />
                    </div>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      <div>
                        <label htmlFor='subcategoryName' className='form-label'>
                          <strong>Nombre de la subcategoría:</strong>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='subcategoryName'
                          name='subcategory_name'
                          value={formData.subcategory_name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='subcategoryStatus' className='form-label'>
                          <strong>Estado:</strong>
                        </label>
                        <select
                          className='form-select'
                          id='subcategoryStatus'
                          name='is_active'
                          value={formData.is_active}
                          onChange={handleInputChange}
                        >
                          <option value='true'>Activo</option>
                          <option value='false'>Desactivado</option>
                        </select>
                      </div>
                    </div>

                    <button type='button' className='btn btn-primary' onClick={handleSaveChanges}>
                      Guardar cambios
                    </button>
                  </form>
                ) : (
                  <>
                    {selectedSubcategory.subcategory_img && (
                      <div style={{ width: '150px', maxHeight: '150px', height: 'fit-content' }}>
                        <img
                          src={`data:image/jpeg;base64,${selectedSubcategory.subcategory_img}`}
                          alt={selectedSubcategory.name}
                          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                        />
                      </div>
                    )}
                    <p>
                      <strong>Nombre de la subcategoría: </strong>
                      {selectedSubcategory.subcategory_name}
                    </p>
                    <p>
                      <strong>Categoría a la que pertenece:</strong> {selectedSubcategory.category_name}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isAddPopupVisible && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button onClick={handleClosePopup} style={{ float: 'right', border: 'none', background: 'none', fontSize: '1.5em' }}>
                  &times;
                </button>
                <h5>Añadir Nueva Subcategoría</h5>
              </div>
              <form>
                <div className='mb-3'>
                  <label htmlFor='subcategoryImage' className='form-label'>
                    <strong>Imagen de la subcategoría:</strong> <br />
                    <small>Tamaño requerido: 693x390</small>
                  </label>
                  <input
                    type='file'
                    className='form-control'
                    id='subcategoryImage'
                    name='subcategory_img'
                    onChange={handleFileChange}
                    accept='image/*'
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='subcategory_name' className='form-label'>
                    <strong>Nombre de la subcategoría:</strong>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='subcategory_name'
                    name='subcategory_name'
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ display: 'flex', width: '100%', gap: '1em', flexDirection: 'column' }} className='mb-3'>
                  <div className='mb-3'>
                    <label htmlFor='categories' className='form-label'>
                      <strong>Categoría a la que pertenece:</strong>
                    </label>
                    <select className='form-select' id='categories' name='category_id' value={formData.category_id} onChange={handleInputChange}>
                      {/* Opciones dinámicas */}
                      {categories
                        .slice() // Crea una copia del arreglo original para no modificarlo directamente
                        .sort((a, b) => a.name.localeCompare(b.name)) // Ordena las categorías alfabéticamente por nombre
                        .map((category) => (
                          <option key={category.category_id} value={category.category_id}>
                            {category.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='mb-3' style={{ width: '50%' }}>
                    <label htmlFor='subcategoryStatus' className='form-label'>
                      <strong>Estado:</strong>
                    </label>
                    <select className='form-select' id='subcategoryStatus' name='is_active' value={formData.is_active} onChange={handleInputChange}>
                      <option value='true'>Activo</option>
                      <option value='false'>Desactivado</option>
                    </select>
                  </div>
                </div>
                <button type='button' className='btn btn-primary' onClick={handleAddSubcategory}>
                  Añadir Categoría
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubcategoryList;
